import React, { useState } from 'react';
import { HStack, Spacer, AccordionButton, AccordionPanel, AccordionItem, AccordionIcon, BoxProps, Stack } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { TokenIcons } from '../../../components/TokenIcons';
import { FeeRate } from '../../../components/FeeRate';
import { Direction } from '../../../../components/Direction';
import { Status } from './Status';
import { Amount, Price, Filled, CreateTime } from './OrderInfo';
import { useBreakpointValue } from '@chakra-ui/react';
import { i_text_copy } from '../../../../../style';
import { LimitOrder } from '../../../../../state/models/trade/limitOrder/types';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

export type HistoryListEntryProps = {
    entry: LimitOrder;
} & BoxProps;

export const HistoryListEntry: React.FC<HistoryListEntryProps> = (props) => {
    const { entry, ...rest } = props;
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const [toggle, setToggle] = useState(!entry.isSellTokenX);
    const isMobile = useIsMobile();

    const DetailsButton = (
        <>
            {!isMobile && <Spacer />}
            <AccordionButton color="secondary.500" w="80px" className={i_text_copy} fontSize="12px !important">
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    return isMobile ? (
        <Card {...rest}>
            <AccordionItem border="0">
                <HStack py="8px" pl="30px" pr="30px" spacing="20px" justifyContent="end">
                    <HStack spacing="20px" flexShrink={0}>
                        <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                        <FeeRate tokenA={entry.tokenX} tokenB={entry.tokenY} feeTier={entry.feeTier} initialToggle={toggle} />
                    </HStack>
                    {oneLineMode && <Direction entry={entry} />}

                    {oneLineMode && (
                        <>
                            <Filled
                                {...{
                                    value: entry.filledDecimal,
                                    all: entry.amountDecimal,
                                }}
                            />
                            <CreateTime {...{ value: entry.createTime }} />
                        </>
                    )}
                    {!oneLineMode && DetailsButton}
                </HStack>
                {!oneLineMode && (
                    <AccordionPanel pl="30px">
                        <Stack>
                            <Direction entry={entry} />
                            <Stack justifyContent="space-between">
                                <Amount {...{ value: entry.amountDecimal }} direction="row" />
                                <Price
                                    value={entry.priceDecimal}
                                    tokenA={entry.tokenX}
                                    tokenB={entry.tokenY}
                                    toggle={toggle}
                                    handleToggle={() => {
                                        setToggle(!toggle);
                                    }}
                                    direction="row"
                                />
                                <Filled
                                    {...{
                                        value: entry.filledDecimal,
                                        all: entry.amountDecimal,
                                    }}
                                    direction="row"
                                />
                            </Stack>
                            <Stack spacing="20px">
                                <HStack justifyContent="space-between">
                                    <CreateTime {...{ value: entry.createTime }} direction="row" />
                                    <Status status={entry.status}></Status>
                                </HStack>
                            </Stack>
                        </Stack>
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Card>
    ) : (
        <Card {...rest}>
            <AccordionItem border="0">
                <HStack py="18px" pl="40px" pr="30px" spacing={oneLineMode ? '30px' : '20px'} minW="680px">
                    <HStack spacing="28px" flexShrink={0}>
                        <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                        <FeeRate tokenA={entry.tokenX} tokenB={entry.tokenY} feeTier={entry.feeTier} initialToggle={toggle} />
                    </HStack>
                    {oneLineMode && <Direction entry={entry} ml="-100px" />}
                    <Amount {...{ value: entry.amountDecimal }} />
                    <Price
                        value={entry.priceDecimal}
                        tokenA={entry.tokenX}
                        tokenB={entry.tokenY}
                        toggle={toggle}
                        handleToggle={() => {
                            setToggle(!toggle);
                        }}
                    />
                    {oneLineMode && (
                        <>
                            <Filled
                                {...{
                                    value: entry.filledDecimal,
                                    all: entry.amountDecimal,
                                }}
                            />
                            <CreateTime {...{ value: entry.createTime }} />
                        </>
                    )}
                    <Status status={entry.status}></Status>
                    {!oneLineMode && DetailsButton}
                </HStack>
                {!oneLineMode && (
                    <AccordionPanel>
                        <HStack spacing="40px" pl="80px">
                            <CreateTime {...{ value: entry.createTime }} />
                            <Direction entry={entry} ml="-100px" />
                            <Filled
                                {...{
                                    value: entry.filledDecimal,
                                    all: entry.amountDecimal,
                                }}
                            />
                        </HStack>
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Card>
    );
};

export default HistoryListEntry;
