import { ChainId } from '../../../../types/mod';

export const TAP_PROXY_ADDRESS = {
    [ChainId.BSCTestnet]: '0x2e6195eD946064aC6aAD70847788891F29AB62C9',
    [ChainId.BSC]: '0xF7d38F63aBFD6d27b293733a6553303Cf3933778',
    [ChainId.Aurora]: '0x93E94ef7D2d735fF21C302c765d8A77C1955A311',
    [ChainId.Arbitrum]: '0xe317266a34B9C35285d4050ad784d5190467F489',
    [ChainId.Matic]: '0x7d2aCf0F34958597910Db1D057bCb4908aC37Db1',
    [ChainId.MantleTest]: '0xB2B0cde11223ab8EE62573298afBF3506Ff31755',
    [ChainId.ScrollTestL2]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
    [ChainId.ZkSyncAlphaTest]: '0x30Dd9d18CfC94AD34dD75defb71b189831d46688',
    [ChainId.ZkSyncEra]: '0xe7d7b5cd36dEeD96C2aDe3Da228d9792D2BE756A',
    [ChainId.Icplaza]: '0xFb18f95bf1C52c8B734486f808864FC19E572641',
    [ChainId.ConfluxESpace]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
    [ChainId.Meter]: '0x0E039b3c26560a19f7D6C153EcBf8F25435a69b6',
    [ChainId.Telos]: '0x3F559139C2Fc7B97Ad6FE9B4d1f75149F551DB18',
    [ChainId.OntologyTestnet]: '0x4d140E612e476A6ba54EF1306b2bA398a5dEff09',
    [ChainId.Ontology]: '0x34bc1b87f60e0a30c0e24FD7Abada70436c71406',
    [ChainId.Ultron]: '0x3a2932a74e511C9Dc4CaD60e06eE6D3690Ce2492',
    [ChainId.LineaTest]: '0xEB122DE19FeA9eD039E2d087113db26017f5F91a',
} as Record<ChainId, string>;