import { BoxProps, HStack, Stack, Text, useColorMode, Image } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';
import tokens, { tokenSymbol2token } from '../../../../../../config/tokens';
import { useRematchDispatch } from '../../../../../../hooks/useRematchDispatch';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { ResponseKlineTokenMeta } from '../../../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { RootDispatch } from '../../../../../../state/store';
import { noto_t3, noto_t3_bold } from '../../../../../../style';
import { TokenSymbol } from '../../../../../../types/mod';
import { getColorThemeSelector, toFeeNumber } from '../../../../../../utils/funcs';

type TokenEntryProps = {
    tokenList: TokenInfoFormatted[];
    tokenX: ResponseKlineTokenMeta;
    tokenY: ResponseKlineTokenMeta;
    currentFee: number;
    pool: string;
    isReverse: boolean;
} & BoxProps;

const TokenEntry: React.FC<TokenEntryProps> = (props) => {
    const { tokenList, tokenX, tokenY, currentFee, pool, isReverse, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const history = useHistory();

    const { chainId, web3 } = useWeb3WithDefault();

    const getIcon = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        } as unknown as any;
        return tempToken.icon ?? '/assets/tokens/default.svg';
    };
    const getToken = (symbol: string) => {
        if (tokens[symbol as TokenSymbol]) {
            const findToken = tokenSymbol2token(symbol, chainId);
            if (findToken.address) {
                return findToken;
            }
        }
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        };
        return tempToken;
    };

    return (
        <HStack
            spacing="6px"
            cursor="pointer"
            onClick={() => {
                // todo: get token and fee by pair info (tabs[0])
                const tokenA = getToken(tokenX.symbol) as TokenInfoFormatted;
                const tokenB = getToken(tokenY.symbol) as TokenInfoFormatted;

                const fee = toFeeNumber(currentFee);

                dispatch.proOrderFormState.setTokenPair({ tokenA, tokenB, fee, chainId, web3 }).then((v: boolean) => {
                    if (v) {
                        dispatch.pro.setPoolInfo({
                            tokenX: tokenA,
                            tokenY: tokenB,
                            pool: pool,
                            currentFee: currentFee,
                            isReverseToken: isReverse,
                            isPoolChange: true,
                            intervals: [],
                        });
                        history.push('/trade/pro?market=' + tokenX.symbol + '_' + tokenY.symbol + '_' + currentFee);
                    }
                });
            }}
            {...rest}
        >
            <Stack>
                <Image boxSize="12px" src={getIcon(tokenX.symbol)} fallbackSrc={getIcon(tokenX.symbol)}></Image>
                <Image
                    boxSize="15px"
                    src={getIcon(tokenY.symbol)}
                    fallbackSrc={getIcon(tokenY.symbol)}
                    mt="-5px !important"
                    ml="5px !important"
                ></Image>
            </Stack>
            <HStack className={noto_t3_bold} spacing="0px">
                <Text>{tokenX.symbol}</Text>
                <Text>/</Text>
                <Text>{tokenY.symbol}</Text>
            </HStack>

            <Stack bg={colorTheme('#EEF0F3', '#1B1631')} borderRadius="5px" px="6px" py="1px">
                <Text className={noto_t3} color={colorTheme('#9984A6', '#6A598C')}>
                    {toFeeNumber(currentFee)}
                    {'%'}
                </Text>
            </Stack>
        </HStack>
    );
};
export default TokenEntry;
