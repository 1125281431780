import {
    BoxProps,
    useColorMode,
    HStack,
    VStack,
    Text,
    Box,
    Image,
    Center,
    InputGroup,
    Input,
    InputLeftElement,
    useInterval,
    Stack,
} from '@chakra-ui/react';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';
import { SearchIcon } from '@chakra-ui/icons';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h4 } from '../../../../style';
import JumpButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import { formatNumber } from '../../../../utils/tokenMath';
import { TokenSymbol } from '../../../../types/mod';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import tokens from '../../../../config/tokens';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { VeiZiAmount } from '../../components/VeiZiAmount';
import { LockTime } from '../../components/LockTime';
import { LockModal } from './LockModal';
import { LockOtherModal } from './LockOtherModal';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../../state/store';
import { isNumeric } from '../../../../utils/valid';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { InitUserDataParams, VeiZiNft } from '../../../../state/models/veiZi/types';
import { getVeiZiNft } from '../../../../state/models/veiZi/funcs';
import useVeiZiEntity from '../../../../state/models/hooks/useVeiZiEntity';
import { VEIZI_ADDRESS } from '../../../../config/veizi/veiziContracts';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { FARM_CONFIG } from '../../../../config/bizConfig';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../config/chains';
import { CardTag } from '../../../../iZUMi-UI-toolkit/src/components/CardTag/CardTag';
import CardTagContainer from '../../../../iZUMi-UI-toolkit/src/components/CardTag/Container';

type VeNFTListProps = {} & BoxProps;

export const VeNFTList: React.FC<VeNFTListProps> = (props) => {
    const { ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { chainId, web3, account } = useWeb3WithDefault();
    const [showLockModal, setShowLockModal] = useState(false);
    const [showLockOtherModal, setShowLockOtherModal] = useState(false);

    const [searchedNftId, setSearchedNftId] = useState('0'); // 0 cannot be id of nft
    const [searchedNFT, setSearchedNFT] = useState(undefined as unknown as VeiZiNft);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { veiZi } = useSelector((state: RootState) => state);
    const { gasPrice } = useGasPrice();
    const toast = useCustomToast();

    const veiZiEntity = useVeiZiEntity(VEIZI_ADDRESS[chainId], 'V1');

    const currentTimestamp = veiZi.globalData?.currentTimestamp ?? 0;
    const currentDate = new Date(currentTimestamp * 1000);

    useEffect(() => {
        console.info('trigger init global data', chainId);
        dispatch.veiZi
            .initAll({
                chainId,
                web3,
                account,
            } as InitUserDataParams)
            .catch((e: any) => console.log('init global data error: ', e));
    }, [chainId, dispatch.veiZi, web3, account]);
    const refreshAllData = useCallback(
        () =>
            dispatch.veiZi
                .initAll({
                    chainId,
                    web3,
                    account,
                } as InitUserDataParams)
                .catch((e: any) => console.log('refresh global data error: ', e)),
        [chainId, dispatch.veiZi, web3, account]
    );
    useInterval(() => {
        console.log('trigger refresh global data ', chainId);
        refreshAllData();
    }, 10000);

    const userNftList = veiZi.userData?.nftList ?? [];

    const NFTs: any[] = [];
    for (const veiZiNft of userNftList) {
        NFTs.push({
            nftId: veiZiNft.nftId,
            veiZi: veiZiNft.veiZiDecimal,
            lockTime: getFormatDateDiff(currentDate, new Date(veiZiNft.endTimestamp * 1000)),
            isStaked: veiZiNft.isStaked,
            owner: veiZiNft.owner,
            pendingRewardDecimal: veiZiNft.pendingRewardDecimal,
            apr: veiZiNft.apr,
        });
        //console.log('pending reward decimal: ', veiZiNft.pendingRewardDecimal);
    }

    const tokenStat = (img: any, text: any, value: number, symbol: any) => {
        return (
            <Card
                variant="base"
                w={{ base: '100%', sm: '250px', xl: '288px', xxl: '345px' }}
                h={{ base: '70px', xxl: '44px' }}
                py="12px"
                pl={{ base: '15px', xxl: '20px' }}
                borderRadius="4px"
                bg={colorTheme('linear-gradient(180deg, #F1F1FF 0%, #FAFAFF 100%)', 'linear-gradient(0deg, #291E40, #291E40)')}
            >
                <Stack direction={{ base: 'column', xxl: 'row' }} alignItems={{ base: 'left', xxl: 'center' }}>
                    <Stack direction="row" alignItems="center">
                        <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                        <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                            {text}
                        </Text>
                    </Stack>
                    <Stack direction="row" alignItems="center">
                        <Text
                            fontSize="16px"
                            className={i_text_copy}
                            color={colorTheme('tertiary.800', 'tertiary.50')}
                            pl={{ base: '0px !important', sm: '10px !important' }}
                        >
                            {formatNumber(value, 2, 2)}
                        </Text>

                        <Text fontSize="16px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                            {symbol}
                        </Text>
                    </Stack>
                </Stack>
            </Card>
        );
    };

    const searchCard = () => {
        return isMobile ? (
            <Stack>
                <Card variant="base" w="100%" minH="60px" pt="20px" pb="18px" px="20px" borderRadius="4px">
                    <Stack w="100%" alignItems="start" spacing="16px">
                        <Text fontSize="16px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                            {t('Search NFT')}
                        </Text>

                        <InputGroup w="100%" h="41px">
                            <Input
                                variant="filled"
                                placeholder={t('Please Enter NFT ID.')}
                                size="md"
                                className={i_text_copy_bold}
                                fontSize="12px"
                                onChange={(e) => {
                                    console.log('nftid: ', e.target.value);
                                    if (isNumeric(Number(e.target.value))) {
                                        setSearchedNftId(e.target.value);
                                    }
                                }}
                                defaultValue={searchedNftId === '0' ? '' : searchedNftId}
                            />
                            <InputLeftElement h="100%" mt="0px">
                                <SearchIcon boxSize={5} />
                            </InputLeftElement>
                        </InputGroup>

                        <CustomButton
                            variant="purple"
                            text={t('Search')}
                            w="100%"
                            h="40px"
                            fontClass={i_text_copy_bold}
                            fontSize="12px"
                            onClick={() => {
                                getVeiZiNft(
                                    searchedNftId,
                                    veiZi.globalData?.currentTimestamp ?? 0,
                                    veiZi.globalData?.seconds4Year ?? 0,
                                    chainId,
                                    web3
                                ).then((findNft: VeiZiNft) => {
                                    if (findNft) {
                                        setSearchedNFT({ ...findNft });
                                    }
                                });

                                const findNft = NFTs.find((nft) => {
                                    return nft.nftId === searchedNftId;
                                });
                                if (findNft) {
                                    setSearchedNFT({
                                        ...findNft,
                                    } as unknown as any);
                                } else {
                                    setSearchedNFT(undefined as unknown as any);
                                }
                            }}
                        />
                    </Stack>
                </Card>
                {searchedNFT && (
                    <Card variant="base" w="100%" p={{ base: '17px 20px 17px 20px' }}>
                        <Stack w="100%" justifyContent="space-between">
                            <Stack>
                                <NFTId direction="column" id={searchedNFT.nftId} w={{ base: 'unset', xxl: '75px' }} link="" />
                            </Stack>

                            <HStack>
                                <VeiZiAmount balance={searchedNFT.veiZiDecimal} variant="bottom-icon" w="125px" />
                                <LockTime
                                    time={getFormatDateDiff(currentDate, new Date(searchedNFT.endTimestamp * 1000))}
                                    variant="bottom-icon"
                                    w="170px"
                                />
                            </HStack>
                            <HStack>
                                <Info label={t('Owner')} value={getHiddenAddress(searchedNFT.owner)} w="125px" />

                                <CustomButton
                                    variant="lightPurple"
                                    text={t('Lock More')}
                                    w="137px"
                                    h="40px"
                                    fontClass={i_text_copy_bold}
                                    fontSize="12px"
                                    mt={{ base: '10px !important', xxl: '-8px !important' }}
                                    bg={colorTheme('#F6EBEB', '#5F3B82')}
                                    color={colorTheme('#8C6A6C', '#ECD4FF')}
                                    onClick={() => {
                                        setShowLockOtherModal(true);
                                    }}
                                />
                            </HStack>
                        </Stack>
                    </Card>
                )}
            </Stack>
        ) : (
            <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important">
                <Stack w="100%" alignItems="start" spacing="40px" direction={{ base: 'column', sm: 'row' }}>
                    <Text fontSize="16px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                        {t('Search NFT')} :
                    </Text>

                    <InputGroup
                        w={{ base: '90%', sm: '350px', xl: '500px', xxl: '760px', xlp1: '810px' }}
                        h="41px"
                        mt={{ base: '20px !important', sm: '-8px !important' }}
                    >
                        <Input
                            variant="filled"
                            placeholder={t('Please Enter NFT ID.')}
                            size="md"
                            className={i_text_copy_bold}
                            fontSize="12px"
                            onChange={(e) => {
                                console.log('nftid: ', e.target.value);
                                if (isNumeric(Number(e.target.value))) {
                                    setSearchedNftId(e.target.value);
                                }
                            }}
                            defaultValue={searchedNftId === '0' ? '' : searchedNftId}
                        />
                        <InputLeftElement h="100%" mt="0px">
                            <SearchIcon boxSize={5} />
                        </InputLeftElement>
                    </InputGroup>

                    <CustomButton
                        variant="purple"
                        text={t('Search')}
                        w="104px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                        mt={{ base: '20px !important', sm: '-8px !important' }}
                        onClick={() => {
                            getVeiZiNft(
                                searchedNftId,
                                veiZi.globalData?.currentTimestamp ?? 0,
                                veiZi.globalData?.seconds4Year ?? 0,
                                chainId,
                                web3
                            ).then((findNft: VeiZiNft) => {
                                if (findNft) {
                                    setSearchedNFT({ ...findNft });
                                }
                            });

                            const findNft = NFTs.find((nft) => {
                                return nft.nftId === searchedNftId;
                            });
                            if (findNft) {
                                setSearchedNFT({
                                    ...findNft,
                                } as unknown as any);
                            } else {
                                setSearchedNFT(undefined as unknown as any);
                            }
                        }}
                    />
                </Stack>
                {searchedNFT && (
                    <Card
                        variant="base"
                        w="90%"
                        h={{ base: 'unset', xxl: '74px' }}
                        p={{ base: '17px 20px 17px 20px', sm: '17px 40px 17px 47px' }}
                        mt="20px"
                        ml="3%"
                    >
                        <Stack
                            w="100%"
                            justifyContent="space-between"
                            alignItems={{ base: 'start', sm: 'center' }}
                            direction={{ base: 'column', xxl: 'row' }}
                        >
                            <HStack spacing="40px">
                                <NFTId direction="column" id={searchedNFT.nftId} w={{ base: 'unset', xxl: '75px' }} link="" />
                            </HStack>

                            <Stack direction={{ base: 'column', sm: 'row' }} alignItems={{ base: 'left', sm: 'center' }}>
                                <Info label={t('Owner')} value={getHiddenAddress(searchedNFT.owner)} w="120px" />
                                <VeiZiAmount balance={searchedNFT.veiZiDecimal} />
                                <LockTime
                                    time={getFormatDateDiff(currentDate, new Date(searchedNFT.endTimestamp * 1000))}
                                    ml={{ base: '-8px !important', sm: '0px !important' }}
                                />
                            </Stack>
                            <CustomButton
                                variant="primary2"
                                text={t('Lock More')}
                                w="104px"
                                h="40px"
                                fontClass={i_text_copy_bold}
                                fontSize="12px"
                                mt={{ base: '10px !important', xxl: '-8px !important' }}
                                onClick={() => {
                                    setShowLockOtherModal(true);
                                }}
                            />
                        </Stack>
                    </Card>
                )}
            </Card>
        );
    };

    const EarnedBlock = (nft: any) => {
        return (
            <HStack
                justifyContent="space-between"
                alignItems="center"
                bg={colorTheme('#F9F9FB', '#302543')}
                p={{ base: '6px', sm: '8px 15px 8px 10px' }}
                spacing="10px"
                w={{ base: 'unset', sm: '205px' }}
                mt={{ base: '10px !important', sm: '-6px !important' }}
            >
                <VStack spacing="6px" alignItems="start">
                    <Text className={i_text_copy_bold} color="tertiary.500">
                        {t('Earned')}
                    </Text>
                    <HStack spacing="6px" w="88px">
                        <HStack w="100%" spacing="4px" alignItems="center" pl="4px !important">
                            <Box
                                w="12px"
                                h="12px"
                                borderRadius="8px"
                                bgImage={process.env.PUBLIC_URL + tokens[TokenSymbol.IZI].icon}
                                bgSize="cover"
                            ></Box>

                            <Text className={i_text_copy_bold} color={colorTheme('tertiary.700', 'tertiary.50')}>
                                {formatNumber(nft.pendingRewardDecimal, 2, 2)}
                            </Text>
                        </HStack>
                    </HStack>
                </VStack>
                <CustomButton
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        veiZiEntity.harvest(
                            {
                                onTransactionHash: (e: any) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e, chain);
                                    }
                                    toast('info', 'Harvest: ' + e, undefined, toastLink);
                                },
                                then: (e: any) => {
                                    console.log(e);
                                    toast('success', 'Harvest successfully', undefined, toastLink);
                                    refreshAllData();
                                },
                                catch: (e: any) => {
                                    console.log(e);
                                    toast('error', e.message);
                                },
                            } as MiningCallbacks,
                            gasPrice
                        );
                    }}
                    flexShrink={0}
                    text={t('Harvest')}
                    variant="primary2"
                    fontClass={i_text_copy_bold}
                    w="90px"
                    h="40px"
                ></CustomButton>
            </HStack>
        );
    };
    const StakedBlock = (index: number, nft: any) => {
        return (
            <Card variant="base" w="100%" key={index} px="20px" py="15px">
                <CardTagContainer pos="static" mt="-12px">
                    <CardTag
                        variant="blue"
                        text={t('Staked')}
                        color={colorTheme('#003BD3', '#B0D0FF')}
                        bg={colorTheme('#D6E6FF', '#2F3464')}
                        border={colorTheme('1px solid #84B5FF', '1px solid #3485FF')}
                    />
                </CardTagContainer>

                <HStack w="100%" direction={{ base: 'column', xxl: 'row' }} justifyContent="space-between">
                    <Stack h="100%" spacing="30px">
                        <Stack>
                            <NFTId direction="column" id={nft.nftId} w="75px" link="" />
                            <VeiZiAmount balance={nft.veiZi} variant="bottom-icon" w="unset" />
                        </Stack>
                    </Stack>

                    <Stack
                        h="100%"
                        direction={{ base: 'column', sm: 'row' }}
                        alignItems={{ base: 'start', sm: 'center' }}
                        spacing={{ base: '30px', sm: '0px' }}
                        justifyContent={{ base: 'center', xxl: 'start' }}
                    >
                        <Stack w="100%" justifyContent="space-between">
                            <Info
                                direction="column"
                                label={t('My APR')}
                                value={
                                    (nft.apr ?? 0) < 99.99
                                        ? formatNumber((nft.apr ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) + '%'
                                        : '9999+%'
                                }
                                w="70px"
                            />
                            <LockTime time={nft.lockTime} variant="bottom-icon" w="170px" whiteSpace="nowrap" />
                        </Stack>
                    </Stack>
                </HStack>
                <HStack>
                    {EarnedBlock(nft)}
                    <JumpButton
                        variant="orange"
                        text={t('Manage')}
                        w="104px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                        mt={{ base: '5px !important', xxl: '-8px !important' }}
                        target="/veENT/veNFT-manage"
                        bg={colorTheme('#EEEAFE', '#9D6666')}
                        color={colorTheme('#8C7AA3', '#FFFFFF')}
                        param={{
                            nftId: String(nft.nftId),
                        }}
                    />
                </HStack>
            </Card>
        );
    };
    const LockedBlock = (index: number, nft: any) => {
        return (
            <Card variant="base" w="100%" h={{ base: 'unset', xxl: '74px' }} key={index} px="20px" py="15px">
                <HStack w="100%" direction={{ base: 'column', xxl: 'row' }} justifyContent="space-between">
                    <Stack
                        h="100%"
                        spacing="30px"
                        direction={{ base: 'column', sm: 'row' }}
                        alignItems={{ base: 'left', sm: 'center' }}
                        justifyContent={{ base: 'center', xxl: 'start' }}
                    >
                        <Stack justifyContent="space-between" spacing="15px">
                            <NFTId direction="column" id={nft.nftId} w="75px" link="" />
                            <VeiZiAmount balance={nft.veiZi} variant="bottom-icon" w="unset" />
                        </Stack>
                    </Stack>

                    <Stack h="100%" spacing="15px">
                        <LockTime time={nft.lockTime} w="170px" variant="bottom-icon" alignItems="start" />
                        <JumpButton
                            variant="lightPurple"
                            text={t('Manage')}
                            w="137px"
                            h="40px"
                            fontClass={i_text_copy_bold}
                            fontSize="12px"
                            target="/veENT/veNFT-manage"
                            bg={colorTheme('#EEEAFE', '#9D6666')}
                            color={colorTheme('#8C7AA3', '#FFFFFF')}
                            param={{
                                nftId: String(nft.nftId),
                            }}
                        />
                    </Stack>
                </HStack>
                {nft.isStaked && EarnedBlock(nft)}
            </Card>
        );
    };

    return isMobile ? (
        !account ? (
            <Center w="100%" mt={{ base: '20px !important', sm: '50px !important' }} {...rest}>
                <VStack pb="10px">
                    <Image
                        w="100px"
                        h="83px"
                        src={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                        fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                    ></Image>
                    <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                        {t('Not Connect to Wallet. Please connect to wallet to get start.')}
                    </Text>
                </VStack>
            </Center>
        ) : (
            <>
                <VStack w="100%" alignItems="left" mt="30px !important" spacing="20px">
                    <Card px="22px" py="16px">
                        <Stack w="100%" spacing="13px">
                            <Text fontSize="16px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                                {t('My veENT NFTs')}
                            </Text>

                            <Stack spacing="13px" direction={{ base: 'column', sm: 'row' }} alignItems="center">
                                {tokenStat(
                                    tokens[TokenSymbol.IZI].icon,
                                    t('Total ENT Locked') + ' :',
                                    veiZi.userData?.totalLockedDecimal ?? 0,
                                    // TokenSymbol.IZI
                                    'ENT'
                                )}

                                {tokenStat(
                                    '/assets/tokens/veiZi.svg',
                                    t('Total veENT Amount') + ':',
                                    veiZi.userData?.totalVeiZiDecimal ?? 0,
                                    'veENT'
                                )}
                            </Stack>
                        </Stack>
                    </Card>
                    <CustomButton
                        variant="purple"
                        text={
                            <HStack w="100%" justifyContent="center">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/iZiWhite.svg'} />
                                <Text>{t('Lock iZi')}</Text>
                            </HStack>
                        }
                        w="100%"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            setShowLockModal(true);
                        }}
                    />
                    {NFTs.length === 0 ? (
                        <Text
                            fontSize="14px"
                            className={i_text_copy}
                            color={colorTheme('tertiary.400', 'tertiary.400')}
                            mt="45px !important"
                            w="100%"
                        >
                            {t("You don't have any veNFT. You can lock iZi to acquire some")} .
                        </Text>
                    ) : (
                        NFTs.map((nft: any, index: any) => {
                            return nft.isStaked ? StakedBlock(index, nft) : LockedBlock(index, nft);
                        })
                    )}

                    {searchCard()}
                </VStack>
                {showLockModal && (
                    <LockModal
                        isOpen={showLockModal}
                        refreshAllData={refreshAllData}
                        onClose={() => {
                            setShowLockModal(false);
                        }}
                    />
                )}
                {showLockOtherModal && (
                    <LockOtherModal
                        nft={searchedNFT}
                        isOpen={showLockOtherModal}
                        onSuccess={refreshAllData}
                        onClose={() => {
                            setShowLockOtherModal(false);
                        }}
                    />
                )}
            </>
        )
    ) : !account ? (
        <Center w="100%" mt={{ base: '20px !important', sm: '50px !important' }} {...rest}>
            <VStack pb="10px">
                <Image
                    w="100px"
                    h="83px"
                    src={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                    fallbackSrc={process.env.PUBLIC_URL + '/assets/Prize/notStart.svg'}
                ></Image>
                <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                    {t('Not Connect to Wallet. Please connect to wallet to get start.')}
                </Text>
            </VStack>
        </Center>
    ) : (
        <>
            <VStack w="100%" alignItems="left" mt="30px !important" spacing="20px">
                <Text fontSize="16px" className={i_h4} color={colorTheme('tertiary.700', 'tertiary.100')}>
                    {t('My veENT NFT')}
                </Text>

                <Stack spacing={{ base: '20px', sm: '30px', xxl: '50px' }} direction={{ base: 'column', sm: 'row' }} alignItems="center">
                    {tokenStat(
                        tokens[TokenSymbol.IZI].icon,
                        t('Total ENT Locked') + ' :',
                        veiZi.userData?.totalLockedDecimal ?? 0,
                        // TokenSymbol.IZI
                        'ENT'
                    )}

                    {tokenStat('/assets/tokens/veiZi.svg', t('Total veENT Amount') + ':', veiZi.userData?.totalVeiZiDecimal ?? 0, 'veENT')}
                    <CustomButton
                        variant="purple"
                        text={
                            <HStack w="100%" mx="53px !important">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/iZiWhite.svg'} />
                                <Text>{t('Lock ENT')}</Text>
                            </HStack>
                        }
                        w="204px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="14px"
                        onClick={() => {
                            setShowLockModal(true);
                        }}
                    />
                </Stack>
                {NFTs.length === 0 ? (
                    <Text
                        fontSize="14px"
                        className={i_text_copy}
                        color={colorTheme('tertiary.400', 'tertiary.400')}
                        mt="45px !important"
                        w="100%"
                    >
                        {t("You don't have any veNFT. You can lock iZi to acquire some")} .
                    </Text>
                ) : (
                    NFTs.map((nft: any, index: any) => {
                        return (
                            <Card
                                variant="base"
                                w="100%"
                                h={{ base: 'unset', xxl: '74px' }}
                                key={index}
                                p={{ base: '17px 30px 17px 30px', sm: '17px 40px 17px 47px' }}
                            >
                                <Stack w="100%" direction={{ base: 'column', xxl: 'row' }} justifyContent="space-between">
                                    <Stack
                                        h="100%"
                                        spacing="30px"
                                        direction={{ base: 'column', sm: 'row' }}
                                        alignItems={{ base: 'left', sm: 'center' }}
                                        justifyContent={{ base: 'center', xxl: 'start' }}
                                    >
                                        <NFTId direction="column" id={nft.nftId} w="75px" link="" />
                                        {nft.isStaked && (
                                            <HStack spacing="5px">
                                                <Box w="11px" h="11px" borderRadius="6px" bg="#60DA00" />
                                                <Text className={i_text_copy_bold} color="#60DA00">
                                                    {t('Staked')}
                                                </Text>
                                            </HStack>
                                        )}

                                        {nft.isStaked && (
                                            <Info
                                                direction="column"
                                                label={t('My APR')}
                                                value={
                                                    (nft.apr ?? 0) < 99.99
                                                        ? formatNumber((nft.apr ?? 0) * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0)) +
                                                          '%'
                                                        : '9999+%'
                                                }
                                                w="70px"
                                            />
                                        )}

                                        {nft.isStaked && !isMobile && EarnedBlock(nft)}
                                    </Stack>

                                    <Stack
                                        h="100%"
                                        direction={{ base: 'column', sm: 'row' }}
                                        alignItems={{ base: 'start', sm: 'center' }}
                                        spacing={{ base: '30px', sm: '0px' }}
                                        justifyContent={{ base: 'center', xxl: 'start' }}
                                    >
                                        <VeiZiAmount balance={nft.veiZi} />
                                        <LockTime time={nft.lockTime} ml={{ base: '-8px !important', sm: '0px !important' }} />
                                        <JumpButton
                                            variant="orange"
                                            text={t('Manage')}
                                            w="104px"
                                            h="40px"
                                            fontClass={i_text_copy_bold}
                                            fontSize="12px"
                                            mt={{ base: '5px !important', xxl: '-8px !important' }}
                                            target="/veENT/veNFT-manage"
                                            param={{
                                                nftId: String(nft.nftId),
                                            }}
                                        />
                                    </Stack>
                                </Stack>
                                {nft.isStaked && isMobile && EarnedBlock(nft)}
                            </Card>
                        );
                    })
                )}

                {searchCard()}
            </VStack>
            {showLockModal && (
                <LockModal
                    isOpen={showLockModal}
                    refreshAllData={refreshAllData}
                    onClose={() => {
                        setShowLockModal(false);
                    }}
                />
            )}
            {showLockOtherModal && (
                <LockOtherModal
                    nft={searchedNFT}
                    isOpen={showLockOtherModal}
                    onSuccess={refreshAllData}
                    onClose={() => {
                        setShowLockOtherModal(false);
                    }}
                />
            )}
        </>
    );
};
