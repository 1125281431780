import { TokenInfoFormatted } from '../hooks/useTokenListFormatted';
import { ChainId } from '../types/mod';

export interface BaseChain {
    id: number;
    name: string;
    tokenSymbol: string;
    token: Partial<TokenInfoFormatted>;
    wrappedTokenSymbol?: string;
    wrappedToken?: Partial<TokenInfoFormatted>;
    icon: string;
    scanUrl: string;
    scanName: string;
    vmType: string;
    rpcUrl: string;
    blockDelta?: number; // time for producing a new block
    blockDeltaU?: number; // time for producing a new block average
}

const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/chains/';

export const initialChains: BaseChain[] = [
    {
        id: ChainId.EthereumMainnet,
        name: 'Ethereum',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
        },
        icon: baseURL + 'eth.png',
        scanUrl: 'https://etherscan.io/',
        scanName: 'EtherScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.BSC,
        name: 'BNB-Chain',
        tokenSymbol: 'BNB',
        token: {
            symbol: 'BNB',
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        },
        wrappedTokenSymbol: 'WBNB',
        wrappedToken: {
            symbol: 'WBNB',
            address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        },
        icon: baseURL + 'bnbChain.png',
        scanUrl: 'https://bscscan.com/',
        scanName: 'BscScan',
        vmType: 'EVM',
        rpcUrl: 'https://bsc-dataseed.binance.org',
        blockDelta: 3,
        blockDeltaU: 3,
    },
    {
        id: ChainId.Aurora,
        name: 'Aurora Chain',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xC9BdeEd33CD01541e1eeD10f90519d2C06Fe3feB',
        },
        icon: baseURL + 'aurora.png',
        scanUrl: 'https://aurorascan.dev/',
        scanName: 'AuroraScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.aurora.dev',
        blockDelta: 1.5,
        blockDeltaU: 1.5,
    },
    {
        id: ChainId.Arbitrum,
        name: 'Arbitrum',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
        },
        icon: baseURL + 'arbitrum.svg',
        scanUrl: 'https://arbiscan.io/',
        scanName: 'ArbiScan',
        vmType: 'EVM',
        rpcUrl: 'https://arb1.arbitrum.io/rpc',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Matic,
        name: 'Polygon',
        tokenSymbol: 'Matic',
        token: {
            symbol: 'MATIC',
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        wrappedTokenSymbol: 'WMATIC',
        wrappedToken: {
            symbol: 'WMATIC',
            address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
        },
        icon: baseURL + 'matic.png',
        scanUrl: 'https://polygonscan.com/',
        scanName: 'PologonScan',
        vmType: 'EVM',
        rpcUrl: 'https://polygon-rpc.com/',
        blockDelta: 2,
        blockDeltaU: 2.2,
    },
    {
        id: ChainId.Cronos,
        name: 'Cronos',
        tokenSymbol: 'CRO',
        token: {
            symbol: 'CRO',
            address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
        },
        wrappedTokenSymbol: 'WCRO',
        wrappedToken: {
            symbol: 'WCRO',
            address: '0x5C7F8A570d578ED84E63fdFA7b1eE72dEae1AE23',
        },
        icon: baseURL + 'cronos.png',
        scanUrl: 'https://cronoscan.com/',
        scanName: 'CronosScan',
        vmType: 'EVM',
        rpcUrl: 'https://node.croswap.com/rpc',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Optimism,
        name: 'Optimism',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
        },
        icon: baseURL + 'OP.svg',
        scanUrl: 'https://optimistic.etherscan.io/',
        scanName: 'OptimismScan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.optimism.io',
    },

    {
        id: ChainId.ETC,
        name: 'Ethereum Classic',
        tokenSymbol: 'ETC',
        token: {
            symbol: 'ETC',
            address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
        },
        wrappedTokenSymbol: 'WETC',
        wrappedToken: {
            symbol: 'WETC',
            address: '0x1953cab0E5bFa6D4a9BaD6E05fD46C1CC6527a5a',
        },
        icon: baseURL + 'etc.png',
        scanUrl: 'https://blockscout.com/etc/mainnet/',
        scanName: 'EthereumClassicScan',
        vmType: 'EVM',
        rpcUrl: 'https://www.ethercluster.com/etc',
        blockDelta: 12.5,
        blockDeltaU: 14,
    },
    {
        id: ChainId.Gatechain,
        name: 'Gatechain',
        tokenSymbol: 'GT',
        token: {
            symbol: 'GT',
        },
        icon: baseURL + 'GT.png',
        scanUrl: 'https://gatescan.org/',
        scanName: 'GateScan',
        vmType: 'EVM',
        rpcUrl: 'https://evm.gatenode.cc',
    },
    {
        id: ChainId.BSCTestnet,
        name: 'BSC Testnet',
        tokenSymbol: 'BNB',
        token: {
            symbol: 'BNB',
            address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
        },
        wrappedTokenSymbol: 'WBNB',
        wrappedToken: {
            symbol: 'WBNB',
            address: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
        },
        icon: baseURL + 'bsc.png',
        scanUrl: 'https://testnet.bscscan.com/',
        scanName: 'TestnetBscScan',
        vmType: 'EVM',
        rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
        blockDelta: 3,
        blockDeltaU: 3,
    },
    {
        id: ChainId.AuroraTestnet,
        name: 'Aurora Testnet',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
        },
        icon: baseURL + 'aurora.png',
        scanUrl: 'https://testnet.aurorascan.dev/',
        scanName: 'TestnetAuroraScan',
        vmType: 'EVM',
        rpcUrl: 'https://testnet.aurora.dev',
        blockDelta: 1.5,
        blockDeltaU: 1.5,
    },
    {
        id: ChainId.Heco,
        name: 'Heco',
        tokenSymbol: 'HT',
        token: {
            symbol: 'HT',
        },
        icon: baseURL + 'HECO.svg',
        scanUrl: 'https://hecoinfo.com/',
        scanName: 'HecoInfo',
        vmType: 'EVM',
        rpcUrl: 'https://http-mainnet-node.huobichain.com',
    },

    {
        id: ChainId.Fantom,
        name: 'Fantom',
        tokenSymbol: 'FTM',
        token: {
            symbol: 'FTM',
        },
        icon: baseURL + 'fantom.png',
        scanUrl: 'https://ftmscan.com/',
        scanName: 'FtmScan',
        vmType: 'EVM',
        rpcUrl: 'https://rpcapi.fantom.network',
    },
    {
        id: ChainId.Rinkeby,
        name: 'Rinkeby',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
        },
        icon: baseURL + 'eth.png',
        scanUrl: 'https://rinkeby.etherscan.io/',
        scanName: 'EtherScan',
        vmType: 'EVM',
        rpcUrl: 'https://rinkeby.infura.io/v3/',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.ZkSyncAlphaTest,
        name: 'ZkSync Testnet',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x8C3e3f2983DB650727F3e05B7a7773e4D641537B',
        },
        icon: baseURL + 'zksync.png',
        scanUrl: 'https://zksync2-testnet.zkscan.io/',
        scanName: 'zkSync scan',
        vmType: 'ZKVM',
        rpcUrl: 'https://zksync2-testnet.zksync.dev',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.ZkSyncEra,
        name: 'zkSync Era',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
        },
        icon: baseURL + 'zksync.png',
        scanUrl: 'https://explorer.zksync.io/',
        scanName: 'zkSync scan',
        vmType: 'ZKVM',
        rpcUrl: 'https://zksync2-mainnet.zksync.io',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.OntologyTestnet,
        name: 'Ontology Testnet',
        tokenSymbol: 'ONG',
        token: {
            symbol: 'ONG',
            address: '0xe8cf015f797877a9a23e80447429c0b0f04e114b',
        },
        wrappedTokenSymbol: 'WONG',
        wrappedToken: {
            symbol: 'WONG',
            address: '0xe8cf015f797877a9a23e80447429c0b0f04e114b',
        },
        icon: baseURL + 'ont.png',
        scanUrl: 'https://explorer.ont.io/testnet',
        scanName: 'ontology test scan',
        vmType: 'EVM',
        rpcUrl: 'https://polaris1.ont.io:10339',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Ontology,
        name: 'Ontology',
        tokenSymbol: 'ONG',
        token: {
            symbol: 'ONG',
            address: '0xd8bc24cfd45452ef2c8bc7618e32330b61f2691b',
        },
        wrappedTokenSymbol: 'WONG',
        wrappedToken: {
            symbol: 'WONG',
            address: '0xd8bc24cfd45452ef2c8bc7618e32330b61f2691b',
        },
        icon: baseURL + 'ont.png',
        scanUrl: 'https://explorer.ont.io/',
        scanName: 'ontology test scan',
        vmType: 'EVM',
        rpcUrl: 'https://dappnode1.ont.io:10339',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.MantleTest,
        name: 'Mantle Testnet',
        tokenSymbol: 'BIT',
        token: {
            symbol: 'BIT',
            address: '0x69AC69b272f96F5f17DDD9da3832ad9Dc86D1d8A',
        },
        wrappedTokenSymbol: 'WBIT',
        wrappedToken: {
            symbol: 'WBIT',
            address: '0x69AC69b272f96F5f17DDD9da3832ad9Dc86D1d8A',
        },
        icon: baseURL + 'mantle.png',
        scanUrl: 'https://explorer.testnet.mantle.xyz',
        scanName: 'mantle test scan',
        vmType: 'EVM',
        rpcUrl: 'https://rpc.testnet.mantle.xyz',
        blockDelta: 1,
        blockDeltaU: 1,
    },
    {
        id: ChainId.ScrollTestL2,
        name: 'Scroll Testnet',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xa1EA0B2354F5A344110af2b6AD68e75545009a03',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0xa1EA0B2354F5A344110af2b6AD68e75545009a03',
        },
        icon: baseURL + 'scroll.png',
        scanUrl: 'https://blockscout.scroll.io/',
        scanName: 'scroll test scan',
        vmType: 'EVM',
        rpcUrl: 'https://alpha-rpc.scroll.io/l2',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Icplaza,
        name: 'Icplaza',
        tokenSymbol: 'ICT',
        token: {
            symbol: 'ICT',
            address: '0xc59d478873d11CCc68F9c63571E821a253c5B605',
        },
        wrappedTokenSymbol: 'WICT',
        wrappedToken: {
            symbol: 'WICT',
            address: '0xc59d478873d11CCc68F9c63571E821a253c5B605',
        },
        icon: baseURL + 'icplaza.png',
        scanUrl: 'https://browsemainnet.ic-plaza.org/index/',
        scanName: 'icplaza scan',
        vmType: 'EVM',
        rpcUrl: 'https://rpcmainnet.ic-plaza.org/',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.ConfluxESpace,
        name: 'Conflux',
        tokenSymbol: 'CFX',
        token: {
            symbol: 'CFX',
            address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
        },
        wrappedTokenSymbol: 'WCFX',
        wrappedToken: {
            symbol: 'WCFX',
            address: '0x14b2d3bc65e74dae1030eafd8ac30c533c976a9b',
        },
        icon: baseURL + 'cfx.png',
        scanUrl: 'https://evm.confluxscan.net',
        scanName: 'conflux espace scan',
        vmType: 'EVM',
        rpcUrl: 'https://evm.confluxrpc.com',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Meter,
        name: 'Meter',
        tokenSymbol: 'MTR',
        token: {
            symbol: 'MTR',
            address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083',
        },
        wrappedTokenSymbol: 'WMTR',
        wrappedToken: {
            symbol: 'WMTR',
            address: '0x160361ce13ec33C993b5cCA8f62B6864943eb083',
        },
        icon: baseURL + 'mtr.png',
        scanUrl: 'https://scan.meter.io',
        scanName: 'meter scan',
        vmType: 'EVM',
        rpcUrl: 'https://pokt.network',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Telos,
        name: 'Telos',
        tokenSymbol: 'TLOS',
        token: {
            symbol: 'TLOS',
            address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        },
        wrappedTokenSymbol: 'WTLOS',
        wrappedToken: {
            symbol: 'WTLOS',
            address: '0xD102cE6A4dB07D247fcc28F366A623Df0938CA9E',
        },
        icon: baseURL + 'tlos.png',
        scanUrl: 'https://www.teloscan.io/',
        scanName: 'telo scan',
        vmType: 'EVM',
        rpcUrl: 'https://mainnet.telos.net/evm',
        blockDelta: 12,
        blockDeltaU: 12,
    },
    {
        id: ChainId.Goerli,
        name: 'Goerli',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0xb4fbf271143f4fbf7b91a5ded31805e42b2208d6',
        },
        icon: baseURL + 'eth.png',
        scanUrl: 'https://goerli.etherscan.io/',
        scanName: 'goerliScan',
        vmType: 'EVM',
        rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
        blockDelta: 14,
        blockDeltaU: 14,
    },
    {
        id: ChainId.MaticTestnet,
        name: 'Polygon Mumbai Testnet',
        tokenSymbol: 'Matic',
        token: {
            symbol: 'Matic',
        },
        icon: baseURL + 'matic.png',
        scanUrl: 'https://mumbai.polygonscan.com/',
        scanName: 'PolygonMumbaiScan',
        vmType: 'EVM',
        rpcUrl: 'https://rpc-mumbai.maticvigil.com',
    },
    {
        id: ChainId.Harmony,
        name: 'Harmony',
        tokenSymbol: 'ONE',
        token: {
            symbol: 'ONE',
        },
        icon: baseURL + 'harmony.png',
        scanUrl: 'https://explorer.harmony.one/#/',
        scanName: 'HarmonyScan',
        vmType: 'EVM',
        rpcUrl: 'https://api.harmony.one',
    },
    {
        id: ChainId.Ultron,
        name: 'Ultron',
        tokenSymbol: 'ULX',
        token: {
            symbol: 'ULX',
            address: '0xb1183357745D3fD7d291E42a2c4B478cdB5710c6',
        },
        wrappedTokenSymbol: 'wULX',
        wrappedToken: {
            symbol: 'wULX',
            address: '0xb1183357745D3fD7d291E42a2c4B478cdB5710c6',
        },
        icon: baseURL + 'ulx.png',
        scanUrl: 'https://ulxscan.com',
        scanName: 'UltronScan',
        vmType: 'EVM',
        rpcUrl: 'https://ultron-rpc.net',
    },
    {
        id: ChainId.LineaTest,
        name: 'Linea Testnet',
        tokenSymbol: 'ETH',
        token: {
            symbol: 'ETH',
            address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
        },
        wrappedTokenSymbol: 'WETH',
        wrappedToken: {
            symbol: 'WETH',
            address: '0x67A1f4A939b477A6b7c5BF94D97E45dE87E608eF',
        },
        icon: baseURL + 'linea.png',
        scanUrl: 'https://explorer.goerli.linea.build/',
        scanName: 'Linea Testnet Explorer',
        vmType: 'EVM',
        rpcUrl: 'https://rpc.goerli.linea.build/',
    },
];

const lookupTableReducer = (table: Dictionary<number>, next: BaseChain, index: number) => {
    table[next.id] = index;
    return table;
};

const chains = (() => {
    const supportedIds = (() => {
        if (process.env.REACT_APP_ENV === 'production') {
            return {
                all: [
                    ChainId.EthereumMainnet,
                    ChainId.BSC,
                    //ChainId.Aurora,
                    ChainId.Arbitrum,
                    ChainId.ZkSyncEra,
                    ChainId.Matic,
                    //ChainId.Meter,
                    //ChainId.Telos,
                    //ChainId.Ontology,
                    //ChainId.Cronos,
                    //ChainId.ETC,
                    //ChainId.ConfluxESpace,
                ],
                farmUni: [ChainId.EthereumMainnet, ChainId.Arbitrum, ChainId.Matic],
                farmiZi: [ChainId.BSC, ChainId.Arbitrum, ChainId.ZkSyncEra],
                trade: [
                    ChainId.EthereumMainnet,
                    ChainId.BSC,
                    //ChainId.Aurora,
                    ChainId.Arbitrum,
                    ChainId.ZkSyncEra,
                    ChainId.Matic,
                    //ChainId.Meter,
                    //ChainId.Ontology,
                    //ChainId.Telos,
                    //ChainId.Cronos,
                    //ChainId.ETC,
                    //ChainId.Icplaza,
                    //ChainId.ConfluxESpace,
                ],
                veiZi: [ChainId.EthereumMainnet],
            };
        } else {
            return {
                all: [
                    ChainId.Goerli,
                    ChainId.Rinkeby,
                    ChainId.BSCTestnet,
                    ChainId.AuroraTestnet,
                    ChainId.ZkSyncAlphaTest,
                    ChainId.MantleTest,
                    ChainId.ScrollTestL2,
                    ChainId.OntologyTestnet,
                    ChainId.LineaTest,
                ],
                farmUni: [ChainId.BSCTestnet, ChainId.Goerli],
                farmiZi: [ChainId.BSCTestnet, ChainId.AuroraTestnet],
                trade: [
                    ChainId.BSCTestnet,
                    ChainId.AuroraTestnet,
                    ChainId.Rinkeby,
                    ChainId.ZkSyncAlphaTest,
                    ChainId.MantleTest,
                    ChainId.ScrollTestL2,
                    ChainId.OntologyTestnet,
                    ChainId.LineaTest,
                ],
                veiZi: [ChainId.BSCTestnet],
            };
        }
    })();

    const initialChainsLookupTable = initialChains.reduce(lookupTableReducer, {});
    const reducer = (endChains: BaseChain[], id: number) => {
        if (initialChainsLookupTable[id] !== undefined) {
            endChains.push(initialChains[initialChainsLookupTable[id]]);
        } else {
            console.error(`Chain ID not found: ${id}`);
        }
        return endChains;
    };

    return {
        all: supportedIds.all.reduce<BaseChain[]>(reducer, []),
        farmUni: supportedIds.farmUni.reduce<BaseChain[]>(reducer, []),
        farmiZi: supportedIds.farmiZi.reduce<BaseChain[]>(reducer, []),
        trade: supportedIds.trade.reduce<BaseChain[]>(reducer, []),
        veiZi: supportedIds.veiZi.reduce<BaseChain[]>(reducer, []),
    };
})();

export const chainLookupTable = {
    all: chains.all.reduce<Dictionary<number>>(lookupTableReducer, {}),
    farmUni: chains.farmUni.reduce<Dictionary<number>>(lookupTableReducer, {}),
    farmiZi: chains.farmiZi.reduce<Dictionary<number>>(lookupTableReducer, {}),
    trade: chains.trade.reduce<Dictionary<number>>(lookupTableReducer, {}),
    veiZi: chains.veiZi.reduce<Dictionary<number>>(lookupTableReducer, {}),
} as any;

export const getChain = (chainId: ChainId) => {
    return chains.all.find((chain) => {
        return chain.id === chainId;
    });
};

export const getTxLink = (tx: string, chain: BaseChain) => {
    let url = chain.scanUrl;
    if (url.substring(url.length - 1) !== '/') {
        url += '/';
    }
    return url + 'tx/' + tx;
};

export const getScanUrlPrefix = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl + 'address/';
};

export const getScanUrl = (chainId: ChainId | undefined): string => {
    if (chainId === undefined) {
        return '';
    }
    const chain = chains.all.find((chain) => {
        return chain.id === chainId;
    });
    return chain?.scanUrl ?? '';
};

const SECONDS_PER_DAY = 24 * 60 * 60;

export const blocksPerDay = (chainId: ChainId) => {
    const chain = getChain(chainId);
    return SECONDS_PER_DAY / (chain?.blockDelta ?? 10);
};

export const chainPriorityFee = {
    [ChainId.BSC]: 0,
    [ChainId.BSCTestnet]: 0,
    [ChainId.EthereumMainnet]: 1.5,
    [ChainId.Arbitrum]: 0.01,
    [ChainId.Matic]: 2.5,
    [ChainId.Aurora]: 0,
    [ChainId.AuroraTestnet]: 0,
    [ChainId.ConfluxESpace]: 0,
} as any;

export default chains;
