import React, { useState } from 'react';
import { HStack, AccordionButton, AccordionPanel, AccordionItem, Box, AccordionIcon, BoxProps, Stack } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Direction } from '../../../../components/Direction';
import { Amount, Price, Filled, CreateTime, OrderId } from './OrderInfo';
import { useBreakpointValue } from '@chakra-ui/react';
import { i_text_copy } from '../../../../../style';
import { ReadyToClaimTag } from './ReadyToClaimTag';
import { LimitOrder } from '../../../../../state/models/trade/limitOrder/types';
import { Status } from './Status';
import useIsMobile from '../../../../../hooks/useIsMobile';

export type HistoryGroupedListEntryProps = {
    entrys: LimitOrder[];
} & BoxProps;

export const HistoryGroupedListEntry: React.FC<HistoryGroupedListEntryProps> = (props) => {
    const { entrys, ...rest } = props;
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const isMobile = useIsMobile();
    const [toggle, setToggle] = useState(false);

    const DetailsButton = (
        <>
            <AccordionButton
                color="secondary.500"
                w="80px"
                className={i_text_copy}
                fontSize="12px !important"
                mx="0px !important"
                px="0px !important"
                ml="20px !important"
            >
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    return isMobile ? (
        <Card {...rest} position="relative" borderColor="primary.300">
            {entrys.map((entry, i) => (
                <Box key={i}>
                    {Number(entry.pending) > 0 && <ReadyToClaimTag />}
                    <AccordionItem border="0">
                        <Stack py="8px" pl="40px" pr="30px" spacing="20px" minW={{ base: '100%', sm: '660px' }} justifyContent="end">
                            <OrderId {...{ value: entry.orderId }} />

                            {oneLineMode && <Direction entry={entry} ml="-100px" />}
                            <Amount {...{ value: entry.amountDecimal }} direction="row" />
                            <Price
                                value={entry.priceDecimal}
                                tokenA={entry.tokenX}
                                tokenB={entry.tokenY}
                                toggle={toggle}
                                handleToggle={() => {
                                    setToggle(!toggle);
                                }}
                                direction="row"
                            />
                            <Filled
                                {...{
                                    value: entry.filledDecimal,
                                    all: entry.amountDecimal,
                                }}
                                direction="row"
                            />
                            {oneLineMode && (
                                <>
                                    <CreateTime {...{ value: entry.createTime }} />
                                </>
                            )}
                            <Box ml="30px !important">
                                <Status status={entry.status}></Status>
                            </Box>
                            {!oneLineMode && DetailsButton}
                        </Stack>
                        {!oneLineMode && (
                            <AccordionPanel>
                                <Stack spacing="20px" pl="20px">
                                    <CreateTime {...{ value: entry.createTime }} direction="row" />
                                    <Direction entry={entry} />
                                </Stack>
                            </AccordionPanel>
                        )}
                    </AccordionItem>
                </Box>
            ))}
        </Card>
    ) : (
        <Card {...rest} position="relative" borderColor="primary.300">
            {entrys.map((entry, i) => (
                <Box key={i}>
                    {Number(entry.pending) > 0 && <ReadyToClaimTag />}
                    <AccordionItem border="0">
                        <HStack py="8px" pl="40px" pr="30px" spacing="20px" minW={{ base: '100%', sm: '660px' }} justifyContent="end">
                            <OrderId {...{ value: entry.orderId }} />

                            {oneLineMode && <Direction entry={entry} ml="-100px" />}
                            <Amount {...{ value: entry.amountDecimal }} />
                            <Price
                                value={entry.priceDecimal}
                                tokenA={entry.tokenX}
                                tokenB={entry.tokenY}
                                toggle={toggle}
                                handleToggle={() => {
                                    setToggle(!toggle);
                                }}
                            />
                            <Filled
                                {...{
                                    value: entry.filledDecimal,
                                    all: entry.amountDecimal,
                                }}
                            />
                            {oneLineMode && (
                                <>
                                    <CreateTime {...{ value: entry.createTime }} />
                                </>
                            )}
                            <Box ml="30px !important">
                                <Status status={entry.status}></Status>
                            </Box>
                            {!oneLineMode && DetailsButton}
                        </HStack>
                        {!oneLineMode && (
                            <AccordionPanel>
                                <HStack spacing="20px" pl="142px">
                                    <CreateTime {...{ value: entry.createTime }} />
                                    <Direction entry={entry} />
                                </HStack>
                            </AccordionPanel>
                        )}
                    </AccordionItem>
                </Box>
            ))}
        </Card>
    );
};

export default HistoryGroupedListEntry;
