import { links } from '../links';

export const config = {
    pages: [
        {
            name: 'ENTSwap',
            img: 'Trade.svg',
            address: '/trade/swap',
            marker: 'new',
            children: [
                {
                    name: 'Swap',
                    address: '/trade/swap',
                    icon: 'Swap.svg',
                },
                {
                    name: 'Limit Order',
                    address: '/trade/limit',
                    icon: 'LimitOrder.svg',
                },
                {
                    name: 'Pro',
                    address: '/trade/pro',
                    icon: 'Pro.svg',
                },
                {
                    name: 'Liquidity',
                    address: '/trade/liquidity',
                    icon: 'Liquidity.svg',
                },
                // {
                //     name: 'Campaign',
                //     address: '/trade/campaign',
                //     icon: 'Campaign.svg',
                // },
                {
                    name: 'Analytics',
                    address: links.analyticLink,
                    icon: 'Analytics.svg',
                },
            ],
        },
        // {
        //     name: 'LiquidBox',
        //     img: 'Farm.svg',
        //     address: '/farm',
        //     children: [
        //         {
        //             name: 'ENTSwap Farm',
        //             address: '/farm/ent/dynamic',
        //             icon: 'iZiSwapFarm.svg',
        //         },
        //         // {
        //         //     name: 'Uniswap V3 Farm',
        //         //     address: '/farm/uni/dynamic',
        //         //     icon: 'UniswapFarm.svg',
        //         // },
        //     ],
        // },
        // {
        //     name: 'veENT',
        //     img: 'VeiZi.svg',
        //     address: '/veENT/locker',
        //     children: [
        //         {
        //             name: 'Locker',
        //             address: '/veENT/locker',
        //             icon: 'Locker.svg',
        //         },
        //     ],
        // },
        {
            name: 'more-info',
            img: 'More.svg',
            address: undefined,
            children: [
                // {
                //     name: 'Github',
                //     address: links.github,
                //     icon: 'Github.svg',
                // },
                {
                    name: 'Docs',
                    address: links.docs,
                    icon: 'Docs.svg',
                },
                // {
                //     name: 'Developer docs',
                //     address: links.developerDocs,
                //     icon: 'DeveloperDocs.svg',
                // },
            ],
        },
    ],
} as { pages: PageConfig[] };
