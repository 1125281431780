import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, Divider, Center, Stack } from '@chakra-ui/react';
import { getColorThemeSelector, getFormatDateDiff, getHiddenAddress } from '../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../../style';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { ToastLink, useCustomToast } from '../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import Info from '../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { NFTId } from '../../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';

import 'react-datepicker/dist/react-datepicker.css';
import './datepicker.css';
import { VeiZiAmount } from '../../components/VeiZiAmount';
import { LockTime } from '../../components/LockTime';
import { VeiZiNft } from '../../../../state/models/veiZi/types';
import { RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { VEIZI_ADDRESS } from '../../../../config/veizi/veiziContracts';
import useVeiZiEntity from '../../../../state/models/hooks/useVeiZiEntity';
import { MiningCallbacks } from '../../../../state/models/hooks/farm/common/callbacks';
import { useGasPrice } from '../../../../hooks/useGasPrice';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../../../config/chains';

type StakeModalProps = {
    isOpen: boolean | any;
    onClose: () => void;
    entry: VeiZiNft;
    onSuccess?: () => void;
} & BoxProps;

export const StakeModal: React.FC<StakeModalProps> = (props) => {
    const { isOpen, onClose, entry, onSuccess, ...rest } = props;
    const { t } = useTranslation();
    const { account, chainId } = useWeb3WithDefault();

    //console.log('entry: ', entry);

    const { veiZi } = useSelector((state: RootState) => state);

    let hasStaked = false;
    const userNftList: VeiZiNft[] = veiZi.userData?.nftList ?? [];
    let entryStaked = undefined as unknown as VeiZiNft;
    for (const nft of userNftList) {
        if (nft.isStaked) {
            hasStaked = true;
            entryStaked = nft;
        }
    }
    const currentTimestamp = veiZi.globalData?.currentTimestamp ?? 0;
    const startDate = new Date(currentTimestamp * 1000);
    const currentNftUnlockDate = new Date(entry.endTimestamp * 1000);
    const stakedNftUnlockDate = new Date(entryStaked?.endTimestamp * 1000 ?? currentTimestamp);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const toast = useCustomToast();

    const veiZiAddress = VEIZI_ADDRESS[chainId];

    const veiZiEntity = useVeiZiEntity(veiZiAddress, 'V2');

    const { gasPrice } = useGasPrice();

    //console.log('entry id: ', entry.nftId);

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            w={{ base: '338px', sm: '788px' }}
            title={entry.isStaked ? 'Unstake veNFT' : 'Stake veNFT'}
            {...rest}
        >
            <Divider mt="-20px !important" />

            {entry.isStaked && (
                <Box
                    bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')}
                    w="100%"
                    h={{ base: 'unset', sm: '113px' }}
                    py={{ base: '20px', sm: 'unset' }}
                >
                    <Center w="100%" h="100%" px="10%">
                        <VStack>
                            <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('#FA4D6C', '#C54158')}>
                                Unstake Your veNFT.
                            </Text>

                            <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} textAlign="center">
                                Currently the veNFT is staked. If you unstake it, the pending rewards will be auto claimed. If the NFT is
                                used for boosting some farming pools, remember to claim the rewards before unstaking.
                            </Text>
                        </VStack>
                    </Center>
                </Box>
            )}

            {!entry.isStaked && hasStaked && (
                <Box bg={colorTheme('#FFF0F3', 'rgba(94, 42, 52, 0.46)')} w="100%" h="143px" pt="20px">
                    <Center w="100%" h="100%" px="10%">
                        <VStack>
                            <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('#FA4D6C', '#C54158')}>
                                Replace Existing Stake
                            </Text>

                            <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} textAlign="center">
                                Your have a veNFT staked before. If you stake it, the current NFT will replace the former one. The pending
                                rewards of the staked NFT will be auto claimed. If the staked NFT is used for boosting some farming pools,
                                remember to claim the rewards before replacing it.
                            </Text>
                        </VStack>
                    </Center>
                </Box>
            )}

            {!entry.isStaked && !hasStaked && (
                <Box bg={colorTheme('#F5F9FF', 'rgba(54, 64, 156, 0.38)')} w="100%" h="113px">
                    <Center w="100%" h="100%" px="10%">
                        <VStack>
                            <Text className={i_text_copy} fontWeight="bold" fontSize="16px" color={colorTheme('primary.700', '#5E9CF9')}>
                                Stake Your veNFT
                            </Text>

                            <Text fontSize="12px" className={i_text_copy} color={colorTheme('#4966AE', '#6A8CC0')} textAlign="center">
                                Currently you have no staked NFT. You can choose one NFT to stake. Beside the staking rewards, the staked
                                NFT will boost the APR if you participate the liquidity mining.
                            </Text>
                        </VStack>
                    </Center>
                </Box>
            )}

            <Card
                variant="base"
                w="100%"
                minH="60px"
                pt="22px"
                pb={{ base: '20px', sm: '10px' }}
                pl="20px"
                borderRadius="4px"
                mb="20px !important"
                mt="20px"
            >
                <Stack w="100%" justifyContent="space-between" direction={{ base: 'column', sm: 'row' }}>
                    <HStack spacing="40px">
                        <NFTId direction="column" id={entry.nftId} w="75px" link="" />
                    </HStack>

                    <Stack direction={{ base: 'column', sm: 'row' }}>
                        <Info label="Owner" value={getHiddenAddress(String(account))} w="120px" />
                        <VeiZiAmount balance={entry.veiZiDecimal} />
                        <LockTime
                            time={getFormatDateDiff(startDate, currentNftUnlockDate)}
                            ml={{ base: '-8px !important', sm: '0px !important' }}
                        />
                    </Stack>
                </Stack>
            </Card>
            {!entry.isStaked && hasStaked && (
                <VStack w="100%">
                    <Text fontSize="12px" className={i_text_copy_bold} color="secondary.500" textAlign="center">
                        Replacing
                    </Text>
                    <Image w="16px" h="16px" src={process.env.PUBLIC_URL + '/assets/farm/lightToV.svg'} />
                </VStack>
            )}

            {!entry.isStaked && hasStaked && (
                <Card variant="base" w="100%" minH="60px" pt="22px" pb="10px" pl="20px" borderRadius="4px" mb="20px !important" mt="20px">
                    <HStack w="100%" justifyContent="space-between">
                        <HStack spacing="40px">
                            <NFTId direction="column" id={entryStaked.nftId as unknown as string} w="75px" link="" />
                        </HStack>

                        <HStack>
                            <Info label="Owner" value={getHiddenAddress(String(account))} w="120px" />
                            <VeiZiAmount balance={entryStaked.veiZiDecimal} />
                            <LockTime time={getFormatDateDiff(startDate, stakedNftUnlockDate)} />
                        </HStack>
                    </HStack>
                </Card>
            )}

            {(entry.isStaked || (!entry.isStaked && hasStaked)) && (
                <Text fontSize="12px" className={i_text_copy} color={colorTheme('#FA3155', '#B44358')} textAlign="center">
                    Remember to claim rewards in the farming pool before this action.
                </Text>
            )}

            <VStack w="100%" mt="20px">
                <CustomButton
                    variant="purple"
                    mt="20px !important"
                    text={
                        <HStack w="100%" position="relative">
                            <HStack mx="auto !important">
                                <Image w="17px" h="17px" src={process.env.PUBLIC_URL + '/assets/tokens/iZiWhite.svg'} />
                                <Text>Confirm {entry.isStaked ? ' ' + t('Unstake') : ' ' + t('Stake')}</Text>
                            </HStack>
                        </HStack>
                    }
                    w="304px"
                    h="40px"
                    fontClass={i_text_copy_bold}
                    fontSize="14px"
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        const callbacks = {
                            onTransactionHash: (e: any) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e, chain);
                                }
                                toast('info', 'Stake veiZi NFT: ' + e, undefined, toastLink);
                            },
                            then: (e: any) => {
                                console.log(e);
                                toast('success', 'Stake veiZi NFT successfully', undefined, toastLink);
                                onSuccess?.();
                                onClose();
                            },
                            catch: (e: any) => {
                                console.log(e);
                                toast('error', e.message);
                            },
                        } as MiningCallbacks;
                        if (entry.isStaked) {
                            // unstake
                            console.log('address: ', veiZiAddress);
                            veiZiEntity.unStake(callbacks, gasPrice);
                        } else if (hasStaked) {
                            // first en stake entryStaked
                            // then stake entry
                            veiZiEntity.unStakeAndStake(entry.nftId, callbacks, gasPrice);
                        } else {
                            // stake entry
                            veiZiEntity.stake(entry.nftId, callbacks, gasPrice);
                        }
                    }}
                />
            </VStack>
        </Modal>
    );
};
