import React, { useState } from 'react';
import { HStack, Spacer, AccordionButton, AccordionPanel, AccordionItem, AccordionIcon, BoxProps, Stack } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { TokenIcons } from '../../../components/TokenIcons';
import { FeeRate } from '../../../components/FeeRate';
import { Direction } from '../../../../components/Direction';
import { Amount, Price, Filled, CreateTime, OrderId } from './OrderInfo';
import { Pending } from './Pending';
import { CancelButton } from './CancelButton';
import { useBreakpointValue } from '@chakra-ui/react';
import { i_text_copy } from '../../../../../style';
import { ReadyToClaimTag } from './ReadyToClaimTag';
import { LimitOrder } from '../../../../../state/models/trade/limitOrder/types';
import { LimitOrderHandler } from './MyLimitOrdersContainer';
import useIsMobile from '../../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { useGasPrice } from '../../../../../hooks/useGasPrice';

export type ActiveListEntryProps = {
    entry: LimitOrder;
    isZip: boolean;
} & LimitOrderHandler &
    BoxProps;

export const ActiveListEntry: React.FC<ActiveListEntryProps> = (props) => {
    const { entry, handleCancelOrder, handleClaimOrder, handleRefreshOrderList, isZip, ...rest } = props;
    const oneLineMode = useBreakpointValue({ base: false, xxl: true, '2xl': true })!;
    const isMobile = useIsMobile();

    const readyToClaim = Number(entry.pending) > 0;
    const [toggle, setToggle] = useState(!entry.isSellTokenX);
    const { gasPrice } = useGasPrice();
    const DetailsButton = (
        <>
            {!isMobile && <Spacer />}
            <AccordionButton color="secondary.500" w="80px" className={i_text_copy} fontSize="12px !important">
                <AccordionIcon />
            </AccordionButton>
        </>
    );

    return isMobile ? (
        <Card {...rest} position="relative" border={readyToClaim ? '1px' : '0'} borderColor="primary.300">
            {readyToClaim && <ReadyToClaimTag />}
            <AccordionItem border="0">
                <HStack py="18px" pl="30px" pr="30px" spacing="20px" justifyContent="end">
                    {isZip ? (
                        <OrderId {...{ value: entry.orderId }} />
                    ) : (
                        <HStack spacing="20px" flexShrink={0}>
                            <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                            <FeeRate tokenA={entry.tokenX} tokenB={entry.tokenY} feeTier={entry.feeTier} initialToggle={toggle} />
                        </HStack>
                    )}
                    {oneLineMode && <Direction entry={entry} />}

                    {!oneLineMode && DetailsButton}
                </HStack>
                {!oneLineMode && (
                    <AccordionPanel pl="30px">
                        <Stack>
                            <Direction w="100%" entry={entry} />
                            <Stack justifyContent="space-between">
                                <Amount {...{ value: entry.amountDecimal, valueStyle: { fontSize: '12px !important' } }} direction="row" />
                                <Price
                                    value={entry.priceDecimal}
                                    tokenA={entry.tokenX}
                                    tokenB={entry.tokenY}
                                    toggle={toggle}
                                    handleToggle={() => {
                                        setToggle(!toggle);
                                    }}
                                    direction="row"
                                    {...{ valueStyle: { fontSize: '12px !important' } }}
                                />
                                <Filled
                                    {...{
                                        value:
                                            entry.filledDecimal + entry.pendingDecimal
                                                ? entry.isSellTokenX
                                                    ? entry.pendingDecimal / entry.priceDecimal
                                                    : entry.pendingDecimal * entry.priceDecimal
                                                : 0,
                                        all: entry.amountDecimal,
                                        direction: 'row',
                                        valueStyle: { fontSize: '12px !important' },
                                    }}
                                />
                            </Stack>

                            <Stack spacing="20px">
                                <Pending
                                    onClaim={() =>
                                        handleClaimOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice).catch((e: any) => {
                                            console.info('error:   ', e.message);
                                        })
                                    }
                                    amount={entry.pendingDecimal}
                                    token={entry.isSellTokenX ? entry.tokenY : entry.tokenX}
                                    h="60px"
                                />
                                <HStack>
                                    <CreateTime {...{ value: entry.createTime }} direction="row" />

                                    <CancelButton
                                        onCancel={() =>
                                            handleCancelOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice).catch((e: any) => {
                                                console.info('error:   ', e.message);
                                            })
                                        }
                                        entry={entry}
                                        w="70px"
                                        h={{ base: '30px', sm: '40px' }}
                                    />
                                </HStack>
                            </Stack>
                        </Stack>
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Card>
    ) : (
        <Card {...rest} position="relative" border={readyToClaim ? '1px' : '0'} borderColor="primary.300">
            {readyToClaim && <ReadyToClaimTag />}
            <AccordionItem border="0">
                <HStack
                    py={{ base: '15px', xxl: '8px' }}
                    pl="40px"
                    pr="30px"
                    spacing={{ base: '12px', xlp1: '18px' }}
                    minW="660px"
                    justifyContent="end"
                >
                    {isZip ? (
                        <OrderId {...{ value: entry.orderId }} />
                    ) : (
                        <HStack spacing="10px" flexShrink={0}>
                            <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                            <FeeRate
                                tokenA={entry.tokenX}
                                tokenB={entry.tokenY}
                                feeTier={entry.feeTier}
                                initialToggle={toggle}
                                w={{ base: '120px', xlp1: '130px' }}
                            />
                        </HStack>
                    )}
                    {oneLineMode && <Direction entry={entry} />}
                    <Amount {...{ value: entry.amountDecimal, valueStyle: { fontSize: '12px !important' } }} />
                    <Price
                        value={entry.priceDecimal}
                        tokenA={entry.tokenX}
                        tokenB={entry.tokenY}
                        toggle={toggle}
                        handleToggle={() => {
                            setToggle(!toggle);
                        }}
                        {...{ valueStyle: { fontSize: '12px !important' } }}
                    />
                    <Filled
                        {...{
                            value:
                                entry.filledDecimal + entry.pendingDecimal
                                    ? entry.isSellTokenX
                                        ? entry.pendingDecimal / entry.priceDecimal
                                        : entry.pendingDecimal * entry.priceDecimal
                                    : 0,
                            all: entry.amountDecimal,
                            valueStyle: { fontSize: '12px !important' },
                        }}
                    />
                    {oneLineMode && (
                        <>
                            <Pending
                                onClaim={() =>
                                    handleClaimOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice)
                                        .then((_) => handleRefreshOrderList())
                                        .catch((e: any) => {
                                            console.info('error:   ', e.message);
                                        })
                                }
                                amount={entry.pendingDecimal}
                                token={entry.isSellTokenX ? entry.tokenY : entry.tokenX}
                            />
                            <CreateTime {...{ value: entry.createTime }} />
                            <CancelButton
                                onCancel={() =>
                                    handleCancelOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice)
                                        .then((_) => handleRefreshOrderList())
                                        .catch((e: any) => {
                                            console.info('error:   ', e.message);
                                        })
                                }
                                entry={entry}
                                w="70px"
                                h="40px"
                            />
                        </>
                    )}
                    {!oneLineMode && DetailsButton}
                </HStack>
                {!oneLineMode && (
                    <AccordionPanel>
                        <HStack spacing="20px" pl="22px">
                            <CreateTime {...{ value: entry.createTime }} />
                            <Direction entry={entry} ml="-100px" />
                            <Pending
                                onClaim={() =>
                                    handleClaimOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice).catch((e: any) => {
                                        console.info('error:   ', e.message);
                                    })
                                }
                                amount={entry.pendingDecimal}
                                token={entry.isSellTokenX ? entry.tokenY : entry.tokenX}
                            />
                            <CancelButton
                                onCancel={() =>
                                    handleCancelOrder(entry.orderId, entry.tokenX, entry.tokenY, gasPrice).catch((e: any) => {
                                        console.info('error:   ', e.message);
                                    })
                                }
                                entry={entry}
                                w="70px"
                                h="40px"
                            />
                        </HStack>
                    </AccordionPanel>
                )}
            </AccordionItem>
        </Card>
    );
};

export default ActiveListEntry;
