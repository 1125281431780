import React, { useEffect } from 'react';
import { Box, Stack, HStack } from '@chakra-ui/react';

import { StatsCard } from './components/StatsCard';
import { VeNFTList } from './components/VeNFTList';
import PageHeader from '../../../components/PageHeader/PageHeader';
import PageLayout from '../../../components/layout/PageLayout';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import VeiZiLinkButton from '../../../components/Buttons/veiZiLinkButton/veiZiLinkButton';
import ValidBlockWrapper from '../../components/ChainNotValidBlock';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

const Locker: React.FC = () => {
    useEffect(() => {
        document.title = 'veiZi | Entropy';
    }, []);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <PageLayout
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%" pt="20px" pb="70px">
                            <Box h="20px" />
                            <StatsCard />
                            <VeNFTList />
                        </Stack>
                    }
                    app="veiZi"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('ENT Locker')}
                    subtitle={t('Lock your ENT to acquire veENT.')}
                    element={
                        <HStack spacing="10px">
                            <VeiZiLinkButton />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <ValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <Box h="20px" />
                            <StatsCard />
                            <VeNFTList />
                        </Stack>
                    }
                    app="veiZi"
                />
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default Locker;
