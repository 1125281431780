import { Divider, Flex, VStack, useColorMode, BoxProps } from '@chakra-ui/react';
import { ReactElement, useEffect, useState } from 'react';
import { getColorThemeSelector } from '../../utils/funcs';
import { announceConfig } from '../../config/announceBar/config';
import AnnouncementBar from '../AnnounceBar/AnnounceBar';
import { useTokenListFormatted } from '../../hooks/useTokenListFormatted';
import { useLocation } from 'react-router-dom';
import { Loading } from '../../views/components/Loading';
import useIsMobile from '../../hooks/useIsMobile';
import { ScrollTab } from '../ScrollTab/ScrollTab';
import useWindowDimensions from '../../iZUMi-UI-toolkit/src/hooks/useWindowDimension';
import useLayoutType from '../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../state/models/pageLayout/pageLayout';

export type AdaptationMode = 'mobile' | 'pc';

export type PageLayoutProps = {
    type?: AdaptationMode;
    header?: ReactElement;
    body: ReactElement;
    internalWidth?: any;
    isShowScrollTab?: boolean;
} & BoxProps;

const PageLayout: React.FC<PageLayoutProps> = (props) => {
    const { type, header, body, internalWidth, isShowScrollTab = true, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { height } = useWindowDimensions();
    const layoutType = useLayoutType();

    const bars = announceConfig.map((item: any, index: any) => {
        return <AnnouncementBar key={index} {...item}></AnnouncementBar>;
    });

    const { loading } = useTokenListFormatted();
    const location = useLocation();
    const notShowDividerUrl = ['/trade/swap', '/trade/limit'];
    const [isShowDivider, setIsShowDivider] = useState(true);

    const showLoading = () => {
        const urls = ['/trade'];
        const url = urls.filter((u) => location.pathname.startsWith(u));
        return url.length;
    };

    useEffect(() => {
        const currentPath = location.pathname;
        setIsShowDivider(!isMobile && !notShowDividerUrl.includes(currentPath));
    }, [location, isMobile]);

    return type === 'mobile' ? (
        <VStack w="100%" h={height - 107} bg={colorTheme('#fbfbfb', '#140E28')} spacing="0" {...rest}>
            {bars}
            <Flex
                pr="0px"
                pl="0px"
                h="100%"
                w="100%"
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                justifyContent="space-between"
                position="relative"
                bg={colorTheme(
                    'linear-gradient(180deg, #F4F2F5 0%, #F9F9F9 100%, #F5F1F9 100%);',
                    'linear-gradient(180deg, #0A0616 0%, #161127 100%)'
                )}
            >
                <VStack
                    mx="auto"
                    w={'100%'}
                    overflowY={{ base: 'auto', sm: 'unset' }}
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                >
                    {isShowScrollTab && <ScrollTab />}

                    {isShowDivider && <Divider m="0 !important" />}

                    <Flex w="100%" direction="column" pr="24px" pl="24px">
                        {loading && showLoading() ? <Loading /> : body}
                    </Flex>
                </VStack>
            </Flex>
        </VStack>
    ) : (
        <VStack w="100%" h="100%" bg={colorTheme('#fbfbfb', '#140E28')} spacing="0" {...rest}>
            {bars}
            <Flex
                pr={{ base: '40px', xlp1: layoutType === LayoutStateEnum.sidebar ? '100px' : '0px' }}
                pl={{ base: '40px', xlp1: '0px' }}
                pt={layoutType == LayoutStateEnum.sidebar ? '40px' : '0px'}
                h="100%"
                w="100%"
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                }}
                justifyContent={{ base: 'space-between', '2xl': 'center' }}
                position="relative"
            >
                <VStack
                    mx="auto"
                    w={
                        internalWidth
                            ? internalWidth
                            : {
                                  base: '700px',
                                  lg: '840px',
                                  xxl: '1100px',
                                  xlp1: '1150px',
                              }
                    }
                    css={{
                        '&::-webkit-scrollbar': {
                            display: 'none !important',
                        },
                    }}
                >
                    {header}

                    {isShowDivider && <Divider m="0 !important" />}

                    <Flex w="100%" direction="column" flexGrow={1}>
                        {loading && showLoading() ? <Loading /> : body}
                    </Flex>
                </VStack>
            </Flex>
        </VStack>
    );
};

export default PageLayout;
