import { Box, BoxProps, Flex, Stack } from '@chakra-ui/react';
import { ReactElement } from 'react';
import BackButton from '../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import { Heading, Text } from '../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import useIsMobile from '../../hooks/useIsMobile';

export type PageHeaderProps = {
    headerTitle: string | ReactElement;
    subtitle: string;
    element?: ReactElement;
    variant?: string;
} & BoxProps;

const PageHeader: React.FC<PageHeaderProps> = (props) => {
    const { headerTitle, subtitle, element, variant, ...rest } = props;
    const isMobile = useIsMobile();

    return !variant ? ( //secondary
        <Flex p="44px 0 20px" direction="row" w="100%" {...rest}>
            <Stack flexGrow={1}>
                {typeof headerTitle === 'string' ? <Heading level="3">{headerTitle}</Heading> : headerTitle}
                <Text variant="display" fontSize={{ base: '14px', xl: '16px' }} color="#8B92A6">
                    {subtitle}
                </Text>
            </Stack>
            {element && <Box flexShrink={0}>{element}</Box>}
        </Flex>
    ) : (
        <Flex p="44px 0 20px" direction={{ base: 'column', sm: 'row' }} w="100%" alignItems="center" {...rest}>
            <BackButton flex={20} mr={{ base: 'auto', sm: 'unset' }} />
            <Stack flex={80} alignItems="center" ml={{ base: '0px', sm: '-20%' }} mt={{ base: '14px', sm: 'unset' }}>
                {typeof headerTitle === 'string' ? <Heading level={isMobile ? '4' : '3'}>{headerTitle}</Heading> : headerTitle}
                <Text variant="display" fontSize={isMobile ? '12px' : 'unset'}>
                    {subtitle}
                </Text>
            </Stack>
        </Flex>
    );
};

export default PageHeader;
