import { Box, BoxProps, HStack, VStack, useColorMode, Stack } from '@chakra-ui/react';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { formatNumber } from '../../../../../utils/tokenMath';
import React, { useMemo } from 'react';
import { i_text_copy_bold } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { isNumeric } from '../../../../../utils/valid';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import useIsMobile from '../../../../../hooks/useIsMobile';

type Props = {
    token: TokenInfoFormatted;
    amount: any;
    onClaim: () => void;
} & BoxProps;

export const Pending: React.FC<Props> = ({ token, amount, onClaim, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const hasPending = useMemo(() => {
        let flag = false;
        if (Number(amount) > 0) {
            flag = true;
        }
        return flag;
    }, [amount]);

    return isMobile ? (
        <HStack
            justifyContent="space-between"
            alignItems="center"
            bg={colorTheme('#F9F9FB', '#302543')}
            p={{ base: '8px 27px 8px 27px', sm: '12px 15px 14px 10px' }}
            spacing={{ base: '6px', sm: '10px' }}
            flexShrink={0}
            w={{ base: 'unset', sm: '192px' }}
            direction={{ base: 'column', sm: 'row' }}
            {...rest}
        >
            <VStack spacing="6px" flexShrink={0} alignItems="start" justifyContent={{ base: 'start', sm: 'center' }}>
                <Text variant="caption" color="tertiary.500">
                    Pending
                </Text>
                <HStack spacing="6px" w="128px">
                    <Box w="16px" h="16px" borderRadius="8px" bgImage={token?.icon} bgSize="cover"></Box>
                    <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {formatNumber(isNumeric(amount) ? amount : 0, 2, 4, true)}
                    </Text>
                </HStack>
            </VStack>
            <CustomButton
                onClick={onClaim}
                text="Claim"
                disabled={!hasPending ? true : false}
                variant="primary2"
                fontClass={i_text_copy_bold}
                w={{ base: '100%', sm: '60px' }}
                h={{ base: '30px', sm: '40px' }}
            ></CustomButton>
        </HStack>
    ) : (
        <Stack
            justifyContent="space-between"
            alignItems="center"
            bg={colorTheme('#F9F9FB', '#302543')}
            p={{ base: '8px 27px 8px 27px', sm: '12px 15px 14px 10px' }}
            spacing={{ base: '6px', sm: '10px' }}
            flexShrink={0}
            w={{ base: '176px', sm: '192px' }}
            direction={{ base: 'column', sm: 'row' }}
            {...rest}
        >
            <VStack
                spacing="6px"
                flexShrink={0}
                alignItems="start"
                justifyContent={{ base: 'start', sm: 'center' }}
                w={{ base: '100%', sm: '60px' }}
            >
                <Text variant="caption" color="tertiary.500">
                    Pending
                </Text>
                <HStack spacing="6px" w="128px">
                    <Box w="16px" h="16px" borderRadius="8px" bgImage={token?.icon} bgSize="cover"></Box>
                    <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {formatNumber(isNumeric(amount) ? amount : 0, 2, 4, true)}
                    </Text>
                </HStack>
            </VStack>
            <CustomButton
                onClick={onClaim}
                flexShrink={0}
                text="Claim"
                disabled={!hasPending ? true : false}
                variant="primary2"
                fontClass={i_text_copy_bold}
                w={{ base: '100%', sm: '60px' }}
                h={{ base: '30px', sm: '40px' }}
            ></CustomButton>
        </Stack>
    );
};
