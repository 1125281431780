import { Flex, VStack, Text } from '@chakra-ui/layout';
import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import Tabs from '../components/Tabs';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import LimitForm from './LimitForm';
import { i_text_d } from '../../../style';

import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import {
    getGenericIziSwapMetaRecord,
    MetaRecordTypeEnum,
    ResponseIziPoolRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { getSortedTokenAddr } from '../../../state/models/common/positionPoolHelper';
import CurrentPriceCard from '../components/CurrentPriceCard';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useLimitOrderManagerContract } from '../../../hooks/useContracts';
import { useBreakpointValue, useColorMode } from '@chakra-ui/react';
import { getColorThemeSelector, toContractFeeNumber } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';
import { useThrottleEffect } from 'ahooks';
import { fetchPoolState } from '../../../state/models/trade/pools/controllers';
import { point2PriceDecimal } from '../../../state/models/trade/utils/priceMath';
import { IChartApi, ISeriesApi, CandlestickData, Time } from 'lightweight-charts';
import {
    RequestIziSwapKLinesRecord,
    getIziSwapKLinesRecord,
    iZiSwapKLinesRecordEnum,
} from '../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import moment from 'moment';
import AdvanceChart from '../Swap/AdvanceSwap/AdvanceChart';

export type AdvanceLimitPros = {
    tabValue: any;
};

const HintText: React.FC<{ text: string }> = ({ text }) => {
    return (
        <VStack h="100%" justifyContent="center">
            <Text className={i_text_d} variant="caption" lineHeight="24px" fontSize="16px">
                {text}
            </Text>
        </VStack>
    );
};

const AdvanceLimit: React.FC<AdvanceLimitPros> = (pros) => {
    const tabValue = pros.tabValue;
    const history = useHistory();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const isXlp1 = useBreakpointValue({ base: false, xxl: false, xlp1: true, '2xl': true });

    const chartRef = useRef<IChartApi>(null);
    const candlesSeries = useRef<ISeriesApi<'Candlestick'>>(null);
    const [candlestickData, setCandlestickData] = useState([] as CandlestickData[]);
    const chartTheme = colorTheme('#ffffff', '#34294A');

    const [iZiPoolAddr, setIZiPoolAddr] = useState<string | undefined>(undefined);
    const [validChart, setValidChart] = useState<boolean>(false);

    const {
        tradeLimitOrder: { limitOrderForm },
    } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const [poolPriceDecimal, setPoolPriceDecimal] = useState<number>(0);

    const [selectedInterval, setSelectedInterval] = useState('15m');
    const [chartLoading, setChartLoading] = useState(false);

    useEffect(() => {
        document.title = t('Exchange') + ' | Entropy';
    }, [t]);

    const { chainId, web3 } = useWeb3WithDefault();

    useThrottleEffect(() => {
        if (limitOrderForm.tokenX.address && limitOrderForm.tokenY.address) {
            // TODO use pool or analytics
            const fee = toContractFeeNumber(limitOrderForm.fee);
            const [tokenX, tokenY] = getSortedTokenAddr(limitOrderForm.tokenX.address, limitOrderForm.tokenY.address);
            getGenericIziSwapMetaRecord<ResponseIziPoolRecord[]>({
                chain_id: chainId,
                type: MetaRecordTypeEnum.IZI_SWAP_POOL,
                pool_tokenx_addr: tokenX.toLowerCase(),
                pool_tokeny_addr: tokenY.toLowerCase(),
                order_by: '-time',
            }).then((r) => {
                const poolMetaList = r.data.is_success ? r.data.data : [];
                setIZiPoolAddr(
                    poolMetaList?.filter((p) => p.fee === fee).sort((a, b) => b.timestamp - a.timestamp)[0]?.address ?? undefined
                );
            });
        } else {
            setValidChart(false);
        }
    }, [chainId, limitOrderForm.tokenX, limitOrderForm.tokenY, limitOrderForm.fee]);

    useEffect(() => {
        if (!iZiPoolAddr) {
            setValidChart(false);
            return;
        }
        setChartLoading(true);
        const buildParam = (): RequestIziSwapKLinesRecord => {
            const page_size = 300;

            const startTime =
                selectedInterval === iZiSwapKLinesRecordEnum.MINUTE_15
                    ? moment()
                          .add(-page_size * 15, 'minute')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : selectedInterval === iZiSwapKLinesRecordEnum.HOUR_1
                    ? moment()
                          .add(-page_size * 1, 'hour')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : selectedInterval === iZiSwapKLinesRecordEnum.DAY
                    ? moment()
                          .add(-page_size * 1, 'day')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : selectedInterval === iZiSwapKLinesRecordEnum.WEEK
                    ? moment()
                          .add(-page_size * 1, 'week')
                          .format('YYYY-MM-DD HH:mm:ss')
                    : selectedInterval === iZiSwapKLinesRecordEnum.MONTH
                    ? moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss')
                    : moment().add(-page_size, 'month').format('YYYY-MM-DD HH:mm:ss');

            return {
                identity: iZiPoolAddr,
                interval: selectedInterval,
                page_size: page_size,
                time_start: startTime,
                time_end: moment().format('YYYY-MM-DD HH:mm:ss'),
            } as RequestIziSwapKLinesRecord;
        };

        getIziSwapKLinesRecord(buildParam()).then((r) => {
            const data = r.data.data;
            const result: any = [];
            data.forEach((item) => {
                result.push({
                    time: item.timestamp as Time,
                    open: Number(item.open),
                    high: Number(item.high),
                    low: Number(item.low),
                    close: Number(item.close),
                    volume: Number(item.volume),
                });
            });
            setCandlestickData(result);
            setChartLoading(false);
            if (data && data.length === 0) {
                setValidChart(false);
            } else {
                setValidChart(true);
            }
        });
    }, [iZiPoolAddr, selectedInterval]);

    const handleChangeTab = (value: string) => {
        if (value === 'Swap') {
            history.push('/trade/swap', { mode: 'advance' });
        }
        if (value === 'Limit Order') {
            history.push('/trade/limit', { mode: 'advance' });
        }
    };

    const limitOrderMgrContract = useLimitOrderManagerContract();

    useEffect(() => {
        if (!limitOrderForm.tokenX || !limitOrderForm.tokenY || !limitOrderForm.fee || !chainId) {
            return;
        }

        fetchPoolState({
            web3,
            chainId,
            baseContract: limitOrderMgrContract,
            tokenA: limitOrderForm.tokenX,
            tokenB: limitOrderForm.tokenY,
            fee: limitOrderForm.fee,
        }).then((r) => {
            if (r) {
                const poolPriceDecimal = point2PriceDecimal(limitOrderForm.tokenX, limitOrderForm.tokenY, Number(r.currentPoint));
                setPoolPriceDecimal(poolPriceDecimal);
            }
        });
    }, [limitOrderMgrContract, limitOrderForm.tokenX, limitOrderForm.tokenY, limitOrderForm.fee, chainId, web3]);

    return (
        <Flex w="1155px">
            <Flex pt="20px">
                <VStack>
                    {!validChart || !iZiPoolAddr ? (
                        <Card w={{ base: '720px', xlp1: '770px' }} h="488px">
                            <HintText text="No related Chart" />
                        </Card>
                    ) : (
                        <AdvanceChart
                            chartWidth={isXlp1 ? 750 : 700}
                            height={488}
                            chartRef={chartRef}
                            series={candlesSeries}
                            data={candlestickData}
                            themeColor={chartTheme}
                            tokenA={limitOrderForm.tokenX}
                            tokenB={limitOrderForm.tokenY}
                            selectedInterval={selectedInterval}
                            setSelectedInterval={setSelectedInterval}
                            chartLoading={chartLoading}
                        ></AdvanceChart>
                    )}
                    <Card w="100%" h="150px" px="29px" py="23px" mt="20px !important">
                        <CurrentPriceCard
                            hidden={!limitOrderForm.tokenX.symbol || !limitOrderForm.tokenY.symbol}
                            tokenA={limitOrderForm.tokenX}
                            tokenB={limitOrderForm.tokenY}
                            price={poolPriceDecimal}
                            handleToggle={() => dispatch.tradeLimitOrder.toggleTokenOrder()}
                            onlyTogglePrice={true}
                            mt="12px !important"
                        />
                    </Card>
                    <CustomButton
                        w="304px"
                        h="50px"
                        text={t('My Orders')}
                        variant="outline"
                        mt="22px !important"
                        mr="30px !important"
                        fontSize="14px"
                        onClick={() => {
                            history.push('/trade/limit/my-orders');
                        }}
                        color={colorTheme('#0166FF', 'tertiary.200')}
                    />
                </VStack>
                <Tabs
                    pl="15px"
                    list={[
                        {
                            label: t('Swap'),
                            value: 'Swap',
                            component: <></>,
                        },
                        {
                            label: t('Limit Order'),
                            value: 'Limit Order',
                            component: <LimitForm />,
                        },
                    ]}
                    value={tabValue}
                    handleClick={handleChangeTab}
                ></Tabs>
            </Flex>
        </Flex>
    );
};

export default AdvanceLimit;
