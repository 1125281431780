import { BigNumber } from 'bignumber.js';
import { useCallback } from 'react';
import { useVeiZiContractWithVersion } from '../../../hooks/useContracts';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { buildSendingParams } from '../../../utils/contractHelpers';
import { MiningCallbacks } from './farm/common/callbacks';

export interface VeiZiEntity {
    createLock: (iZiAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    increaseAmountOrUnlockTime: (nftId: string, iZiAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    stake: (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    unStake: (callbacks: MiningCallbacks, gasPrice: number) => void;
    unStakeAndStake: (stakeNftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    withdraw: (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    safeTransfer: (toAddress: string, nftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
    harvest: (callbacks: MiningCallbacks, gasPrice: number) => void;
    mergeTo: (fromNftId: string, toNftId: string, callbacks: MiningCallbacks, gasPrice: number) => void;
}

export interface MetaParams {
    account: string | null | undefined;
    veiZiContract: any;
    gas?: string;
    gasPrice?: number;
}

const _getCreateLockCall = (iZiAmount: string, endBlockNum: string, metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };

    return [veiZiContract?.methods.createLock(
        iZiAmount, endBlockNum
    ), options];
};

const _getMulticall = (callings: string[], metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.multicall(callings), options];
};

const _getStakeCall = (nftId: string, metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.stake(nftId), options];
};

const _getWithdrawCall = (nftId: string, metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.withdraw(nftId), options];
};

const _getUnStakeCall = (metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.unStake(), options];
};

const _getSafeTransferFromCall = (nftId: string, toAddress: string, metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.safeTransferFrom(account, toAddress, nftId), options];
};

const _getHarvestCall = (metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.collect(), options];
};

const _getMergeCall = (fromNftId: string, toNftId: string, metaParams: MetaParams): any => {
    const {account, veiZiContract, gas, gasPrice} = metaParams;
    const options = {
        from: account,
        gas: gas,
        maxFeePerGas: gasPrice,
    };
    return [veiZiContract?.methods.merge(fromNftId, toNftId), options];
};

const useVeiZiEntity = (veiZiAddress: string, version: string): VeiZiEntity => {
    const { chainId, account } = useWeb3WithDefault();
    // const { gasPrice } = useGasPrice();

    const { contract } = useVeiZiContractWithVersion(veiZiAddress, version);

    const createLock = useCallback(
        (iZiAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => {

            const getCreateLockCall = _getCreateLockCall;

            if (version === 'V3') {
                // update getCreateLockCall
            }
            const [createLockCall, options] = getCreateLockCall(
                iZiAmount, endBlockNum, {account, veiZiContract: contract}
            );

            createLockCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);

                const [sendCreateLockCall, sendOptions] = getCreateLockCall(
                    iZiAmount, endBlockNum, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );

                sendCreateLockCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [version, account, contract, chainId]
    );

    const increaseAmountOrUnlockTime = useCallback(
        (nftId: string, iZiAmount: string, endBlockNum: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const callings: string[] = [];
            if (endBlockNum !== '0') {
                const increaseUnlockTimeCalling = contract?.methods.increaseUnlockTime(nftId, endBlockNum).encodeABI();
                callings.push(increaseUnlockTimeCalling);
            }
            if (iZiAmount !== '0') {
                const increaseAmountCalling = contract?.methods.increaseAmount(nftId, iZiAmount).encodeABI();
                callings.push(increaseAmountCalling);
            }
            const [multicall, options] = _getMulticall(
                callings, {account, veiZiContract: contract}
            );
            multicall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {

                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMulticall, sendOptions] = _getMulticall(
                    callings, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendMulticall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );

    const unStakeAndStake = useCallback(
        (stakeNftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const callings: string[] = [];
            const unStakeCalling = contract?.methods.unStake().encodeABI();
            callings.push(unStakeCalling);
            const stakeCalling = contract?.methods.stake(stakeNftId).encodeABI();
            callings.push(stakeCalling);
            const [multicall, options] = _getMulticall(
                callings, {account, veiZiContract: contract}
            );
            multicall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {

                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMulticall, sendOptions] = _getMulticall(
                    callings, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendMulticall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account , chainId, contract]
    );

    const stake = useCallback(
        (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getStakeCall(
                nftId, {account, veiZiContract: contract}
            );
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getStakeCall(
                    nftId, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendStakeCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );
    const unStake = useCallback(
        (callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getUnStakeCall(
                {account, veiZiContract: contract}
            );
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getUnStakeCall(
                    {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendStakeCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );

    const withdraw = useCallback(
        (nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [stakeCall, options] = _getWithdrawCall(
                nftId, {account, veiZiContract: contract}
            );
            stakeCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendStakeCall, sendOptions] = _getWithdrawCall(
                    nftId, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendStakeCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );

    const safeTransfer = useCallback(
        (toAddress: string, nftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            // todo
            const [safeTransferFromCall, options] = _getSafeTransferFromCall(
                nftId, toAddress, {account, veiZiContract: contract}
            );
            safeTransferFromCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendSafeTransferFromCall, sendOptions] = _getSafeTransferFromCall(
                    nftId, toAddress, {account, veiZiContract: contract, gas: gasLimit, gasPrice}
                );
                sendSafeTransferFromCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );

    const harvest = useCallback(
        (callbacks: MiningCallbacks, gasPrice: number) => {
            const [harvestCall, options] = _getHarvestCall({account, veiZiContract: contract});
            harvestCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendHarvestCall, sendOptions] = _getHarvestCall({account, veiZiContract: contract, gas: gasLimit, gasPrice});
                sendHarvestCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );

    const mergeTo = useCallback(
        (fromNftId: string, toNftId: string, callbacks: MiningCallbacks, gasPrice: number) => {
            const [mergeToCall, options] = _getMergeCall(fromNftId, toNftId, {account, veiZiContract: contract});
            mergeToCall.estimateGas(buildSendingParams(chainId, options, gasPrice)).then((gas: number) => {
                const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);
                const [sendMergeToCall, sendOptions] = _getMergeCall(fromNftId, toNftId, {account, veiZiContract: contract, gas: gasLimit, gasPrice});
                sendMergeToCall.send(buildSendingParams(chainId, sendOptions, gasPrice)).on(
                    'transactionHash',
                    (e: any) => {
                        if (callbacks.onTransactionHash !== undefined) {
                            callbacks.onTransactionHash(e);
                        }
                    }
                ).then((e: any) => {
                    if (callbacks.then !== undefined) {
                        callbacks.then(e);
                    }
                }).catch((e: any) => {
                    if (callbacks.catch !== undefined) {
                        callbacks.catch(e);
                    }
                });
            });
        },
        [account, chainId, contract]
    );
    return {
        createLock,
        increaseAmountOrUnlockTime,
        stake,
        unStake,
        unStakeAndStake,
        withdraw,
        safeTransfer,
        harvest,
        mergeTo
    };
};

export default useVeiZiEntity;