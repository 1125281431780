export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? [
              //   {
              //       text: 'News: iZiSwap now is alive on zkSync Era! Add liquidity to share the zkSync airdrop rewards!',
              //       icon: '',
              //       type: 'link',
              //       linkText: 'Check more.',
              //       onClick: null,
              //       index: 0,
              //   },
          ]
        : [
              {
                  text: 'Testnet only, last update time: 2023.04.10',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
