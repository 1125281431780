import debounce from 'lodash/debounce';
import { config } from '../../config/sidebar/config';
import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BoxProps, Divider, HStack, useColorMode, VStack, Text, Image, Flex } from '@chakra-ui/react';

import { getColorThemeSelector } from '../../iZUMi-UI-toolkit/src/utils/funcs';

export type tabListProps = {
    name: string;
    address?: string;
};

export const ScrollTab: React.FC<BoxProps> = (props) => {
    const { ...rest } = props;
    const history = useHistory();
    const location = useLocation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const [tabList, setTabList] = useState([] as tabListProps[]);
    const currentPath = location.pathname;

    useEffect(() => {
        const currentPath = location.pathname;
        let findParent = config.pages.find((item) => item.address === currentPath || item.children.find((i) => i.address === currentPath));

        if (currentPath === '/farm/uni/one-side' || currentPath === '/farm/uni/dynamic') {
            setTabList([
                ...[
                    { name: 'Gallery', address: '/farm' },
                    { name: 'ENTROPY Farm', address: '/farm/ent/dynamic' },
                    // { name: 'Uniswap V3 Farm', address: currentPath },
                ],
            ]);
            findParent = undefined;
        }
        if (currentPath === '/farm/ent/one-side' || currentPath === '/farm/ent/dynamic') {
            setTabList([
                ...[
                    { name: 'Gallery', address: '/farm' },
                    { name: 'ENTROPY Farm', address: currentPath },
                    // { name: 'Uniswap V3 Farm', address: '/farm/uni/dynamic' },
                ],
            ]);
            findParent = undefined;
        }
        if (currentPath === '/farm/ent/fix') {
            setTabList([
                ...[
                    { name: 'Gallery', address: '/farm' },
                    { name: 'ENTROPY Farm', address: currentPath },
                    // { name: 'Uniswap V3 Farm', address: '/farm/uni/dynamic' },
                ],
            ]);
            findParent = undefined;
        }
        if (currentPath === '/farm/uni/fix') {
            setTabList([
                ...[
                    { name: 'Gallery', address: '/farm' },
                    { name: 'ENTROPY Farm', address: '/farm/ent/fix' },
                    // { name: 'Uniswap V3 Farm', address: currentPath },
                ],
            ]);
            findParent = undefined;
        }

        if (findParent) {
            if (findParent.address === '/farm') {
                setTabList([...[{ name: 'Gallery', address: findParent.address }], ...findParent?.children]);
            } else {
                setTabList(findParent?.children);
            }
        }
    }, [location]);

    const [isHiddenRight, setIsHiddenRight] = useState(false);
    const SUBMENU_CHEVRON_CLICK_MOVE_PX = 120;
    const SUBMENU_SCROLL_DEVIATION = 3;
    const scrollLayerRef = useRef<HTMLDivElement>(null);
    const chevronLeftRef = useRef<HTMLDivElement>(null);
    const chevronRightRef = useRef<HTMLDivElement>(null);

    const layerController = useCallback(() => {
        if (!scrollLayerRef.current || !chevronRightRef.current) return;
        const scrollLayer = scrollLayerRef.current;
        // if (scrollLayer.scrollLeft === 0) chevronLeftRef.current.classList.add('hide');
        // else chevronLeftRef.current.classList.remove('hide');

        // scrollLayer.scrollWidth === 382
        if (scrollLayer.scrollLeft + scrollLayer.offsetWidth < 382 - SUBMENU_SCROLL_DEVIATION) {
            setIsHiddenRight(false);
        } else {
            setIsHiddenRight(true);
        }
    }, []);

    useEffect(() => {
        layerController();
    }, [layerController]);

    return (
        <HStack w="100%" h="38px" px="25px" bg={colorTheme('#ffffff', '#18142D')} position="sticky" top="-2px" zIndex={3} {...rest}>
            <HStack
                ref={scrollLayerRef}
                w={tabList.length <= 3 ? 'unset' : '286px'}
                overflow="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        display: 'none !important',
                    },
                    scrollBehavior: 'smooth',
                }}
                onScroll={debounce(layerController, 10)}
            >
                {tabList.map((item, index) => {
                    const isSelected = currentPath === item.address;
                    return (
                        <VStack
                            pl={index === 0 ? '5px' : '12px'}
                            pr="5px"
                            key={index}
                            onClick={() => {
                                if (item.address) {
                                    if (item.address.startsWith('http')) {
                                        window.open(item.address);
                                    } else {
                                        history.push(item.address);
                                    }
                                }
                            }}
                        >
                            <Text
                                h={isSelected ? '34px' : '39px'}
                                lineHeight={isSelected ? '45px' : '39px'}
                                whiteSpace="nowrap"
                                color={isSelected ? 'secondary.400' : '#9481BA'}
                                fontFamily={isSelected ? 'Montserrat-Bold' : 'Montserrat-Medium'}
                                fontSize="14px"
                            >
                                {item.name}
                            </Text>
                            <Divider
                                w="100%"
                                mt={isSelected ? '5px !important' : '0px !important'}
                                borderWidth={isSelected ? '3px ' : '0px'}
                                borderColor={isSelected ? colorTheme('#7F4AFE', '#8B62FF') : colorTheme('#EBE6F0', '#52466A')}
                                zIndex={isSelected ? 1 : 0}
                                borderRadius="28px"
                                opacity={1}
                            ></Divider>
                        </VStack>
                    );
                })}
            </HStack>

            <Flex
                position="absolute"
                w="100px"
                h="40px"
                bg={colorTheme(
                    'linear-gradient(270deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0.3) 87.5%);',
                    'linear-gradient(270deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 87.5%)'
                )}
                right="10px"
                alignItems="center"
                justifyContent="flex-end"
                zIndex={2}
                hidden={tabList.length <= 3 || isHiddenRight}
                ref={chevronRightRef}
                onClick={() => {
                    if (!scrollLayerRef.current) return;
                    scrollLayerRef.current.scrollLeft += SUBMENU_CHEVRON_CLICK_MOVE_PX;
                }}
            >
                <Image boxSize="12px" src="/assets/scrollTab/ArrowRight.svg" fallbackSrc="/assets/scrollTab/ArrowRight.svg"></Image>
            </Flex>
            {/* <Flex
                ref={chevronRightRef}
                hidden={isHiddenRight}
                onClick={() => {
                    if (!scrollLayerRef.current) return;
                    scrollLayerRef.current.scrollLeft += SUBMENU_CHEVRON_CLICK_MOVE_PX;
                }}
            >
                <Image boxSize="12px" src="/assets/scrollTab/ArrowRight.svg" fallbackSrc="/assets/scrollTab/ArrowRight.svg"></Image>
            </Flex> */}
        </HStack>
    );
};
