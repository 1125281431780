import React from 'react';
import { BoxProps, HStack, Stack, useColorMode, VStack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy_bold } from '../../../style';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { SupportFeeTiers } from '../../../config/trade/feeTiers';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import useIsMobile from '../../../hooks/useIsMobile';
import { AdaptationMode } from '../../../components/layout/PageLayout';

export type FeesTiersSelectProps = {
    handleSelect: (fee: FeeTier) => void;
    currentFee: FeeTier;
    isAdvanceMode?: boolean;
    type?: AdaptationMode;
} & BoxProps;

const FeesTiersSelect: React.FC<FeesTiersSelectProps> = ({ handleSelect, currentFee, isAdvanceMode, type, ...rest }) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();
    const changeLine = SupportFeeTiers[chainId].length / 2;

    const singleTier = (item: any) => {
        const isSelected = currentFee === item;
        return (
            <CustomButton
                key={item}
                variant={isMobile ? 'inlinePurple' : 'outlinePurple'}
                w={isMobile ? '25%' : '75px'}
                h={isMobile ? '28px' : isAdvanceMode ? '34px' : '44px'}
                selected={isSelected}
                onClick={() => handleSelect(item)}
                bgColor={'inherit'}
                borderColor={isSelected ? colorTheme('secondary.600', 'secondary.300') : colorTheme('tertiary.200', 'tertiary.')}
                text={
                    <VStack alignItems="center" p="10px" spacing="4px">
                        <Text
                            className={i_text_copy_bold}
                            variant="caption"
                            fontSize={isAdvanceMode ? '12px' : '13px'}
                            color={isSelected ? colorTheme('tertiary.800', 'tertiary.200') : colorTheme('#8A7A96', 'tertiary.200')}
                        >
                            {Number(item).toFixed(2)} %
                        </Text>
                    </VStack>
                }
                {...rest}
            />
        );
    };

    return type === 'mobile' ? (
        <Stack direction={'row'}>
            <HStack w="100%">
                {SupportFeeTiers[chainId].map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
        </Stack>
    ) : (
        <Stack direction="row">
            <HStack>
                {SupportFeeTiers[chainId].slice(0, changeLine).map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
            <HStack>
                {SupportFeeTiers[chainId].slice(changeLine, SupportFeeTiers[chainId].length).map((item: FeeTier) => {
                    return singleTier(item);
                })}
            </HStack>
        </Stack>
    );
};

export default FeesTiersSelect;
