import { createModel } from '@rematch/core';
import { RootModel } from '../../../../index';
import produce from 'immer';
// import { MintRequest, UniswapPositionManagerContract } from '../../../../../../types/abis/UniswapV3/UniswapPositionManager';
import { MintRequest } from '../../../../../../types/abis/iZiSwap/LiquidityManager';
import {
    findPoolEntryByPoolKey,
} from '../funcs';

import BigNumber from 'bignumber.js';
import { TransactionReceipt } from 'ethereum-abi-types-generator';
import { getSortedTokenAddr } from '../../../../common/positionPoolHelper';
import { izumiFeeToTickSpacingMapping, price2NearestTick } from '../../../../../../utils/tickMath';
import { buildSendingParams } from '../../../../../../utils/contractHelpers';
import { toContractFeeNumber } from '../../../../../../utils/funcs';
import { isGasToken } from '../../../../../../config/tokens';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';
import { ChainId } from '../../../../../../types/mod';
import { tick2Price } from '../../../../../../utils/tickMath';
import { priceDecimal2PriceUndecimal, priceUndecimal2PriceDecimal } from '../../price';
import { calciZiLiquidityAmountDesired } from '../../amountMath';
import { amount2Decimal } from '../../../../../../utils/tokenMath';
import { IZISWAP_MINT_CONFIG } from '../../../../../../config/bizConfig';
import { LiquidityManagerContract } from '../../../../../../types/abis/iZiSwap/LiquidityManager';

export interface MintForm {
    token0: TokenInfoFormatted;
    token1: TokenInfoFormatted;
    fee: FeeTier;
    tickLower: number;
    tickUpper: number;

    amount0Desired: BigNumber;
    amount1Desired: BigNumber;
    amount0DecimalDesired: number;
    amount1DecimalDesired: number;

    tickLowerPrice: number;
    tickUpperPrice: number;
    tickLowerPriceDecimal: number;
    tickUpperPriceDecimal: number;

    defaultTickLowerPrice: number;
    defaultTickUpperPrice: number;
    defaultTickLowerPriceDecimal: number;
    defaultTickUpperPriceDecimal: number;

    isLockFirstTokenVolume: boolean;

    liquidityPoolKey: string;
    spacingMapping: {[index: number]: number};
}

export interface AddMintFormTickParams {
    stepPositive: boolean;
    isUpper: boolean;
    currentTick: number;
}

export interface LiquidityState {
    mintForm: MintForm;
}

export interface MintLiquidityFromiZiSwapParams {
    account: string;
    mintForm: MintForm;
    liquidityManagerContract?: LiquidityManagerContract;
    chainId: ChainId;
    gas?: string;
    gasPrice: string | number;
}

export interface SetFormAmountDecimalDesiredParams {
    isDesired0: boolean;
    desiredAmountDecimal: number;
    currentTick: number;
}

export interface SetMintFormTickPriceParams {
    tickPrice?: number;
    tickPriceDecimal: number;
    isUpper?: boolean;
    currentTick: number;
}


export const farmFixRangeiZiLiquidity = createModel<RootModel>()({
    state: {
        mintForm: {} as MintForm
    } as LiquidityState,
    reducers: {
        saveLiquidity: (state: LiquidityState, payload: LiquidityState) => {
            return { ...state, ...payload };
        },

        setWalletMintForm: (state: LiquidityState, mintForm: MintForm) => produce(state, draft => {
            draft.mintForm = mintForm;
        }),

        addMintFormTick: (state: LiquidityState, addMintFormTickParams: AddMintFormTickParams) => produce(state, ({ mintForm }) => {
            const tickStep = izumiFeeToTickSpacingMapping[mintForm.fee] * (addMintFormTickParams.stepPositive ? 1 : -1);
            if (addMintFormTickParams.isUpper) {
                mintForm.tickUpper += tickStep;
                mintForm.tickUpperPrice = tick2Price(mintForm.tickUpper);
                mintForm.tickUpperPriceDecimal = priceUndecimal2PriceDecimal(mintForm.token0, mintForm.token1, new BigNumber(mintForm.tickUpperPrice))
            } else {
                mintForm.tickLower += tickStep;
                mintForm.tickLowerPrice = tick2Price(mintForm.tickLower);
                mintForm.tickLowerPriceDecimal = priceUndecimal2PriceDecimal(mintForm.token0, mintForm.token1, new BigNumber(mintForm.tickLowerPrice))
            }
            let leftPoint = mintForm.tickLower
            let rightPoint = mintForm.tickUpper
            if (mintForm.token0.address.toLowerCase() > mintForm.token1.address.toLowerCase()) {
                leftPoint = -mintForm.tickUpper
                rightPoint = -mintForm.tickLower
            }
            const amountFilled = new BigNumber(
                mintForm.isLockFirstTokenVolume 
                ? (mintForm.amount0Desired?.toFixed(0) ?? '0')
                : (mintForm.amount1Desired?.toFixed(0) ?? '0')
            )
            const amountOtherCalced = calciZiLiquidityAmountDesired(
                leftPoint,
                rightPoint,
                addMintFormTickParams.currentTick,
                amountFilled,
                mintForm.isLockFirstTokenVolume, 
                mintForm.token0.address, 
                mintForm.token1.address
            )
            if (mintForm.isLockFirstTokenVolume) {
                mintForm.amount1Desired = amountOtherCalced
                mintForm.amount1DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token1) ?? 0
            } else {
                mintForm.amount0Desired = amountOtherCalced
                mintForm.amount0DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token0) ?? 0
            }
        }),

        setMintFormTickPrice: (state: LiquidityState, setMintFormTickPriceParams: SetMintFormTickPriceParams) => produce(state, ({ mintForm }) => {

            const tickPrice = Number(priceDecimal2PriceUndecimal(mintForm.token0, mintForm.token1, setMintFormTickPriceParams.tickPriceDecimal ?? 0))

            const tick = price2NearestTick(tickPrice, mintForm.fee, izumiFeeToTickSpacingMapping);

            if (setMintFormTickPriceParams.isUpper) {
                mintForm.tickUpper = tick;
                mintForm.tickUpperPrice = tick2Price(tick);
                mintForm.tickUpperPriceDecimal = priceUndecimal2PriceDecimal(mintForm.token0, mintForm.token1, new BigNumber(mintForm.tickUpperPrice))
            } else {
                mintForm.tickLower = tick;
                mintForm.tickLowerPrice = tick2Price(tick);
                mintForm.tickLowerPriceDecimal = priceUndecimal2PriceDecimal(mintForm.token0, mintForm.token1, new BigNumber(mintForm.tickLowerPrice))
            }

            let leftPoint = mintForm.tickLower
            let rightPoint = mintForm.tickUpper
            if (mintForm.token0.address.toLowerCase() > mintForm.token1.address.toLowerCase()) {
                leftPoint = -mintForm.tickUpper
                rightPoint = -mintForm.tickLower
            }
            const amountFilled = new BigNumber(
                mintForm.isLockFirstTokenVolume 
                ? (mintForm.amount0Desired?.toFixed(0) ?? '0')
                : (mintForm.amount1Desired?.toFixed(0) ?? '0')
            )
            const amountOtherCalced = calciZiLiquidityAmountDesired(
                leftPoint,
                rightPoint,
                setMintFormTickPriceParams.currentTick,
                // new BigNumber(0),
                amountFilled,
                mintForm.isLockFirstTokenVolume, 
                mintForm.token0.address, 
                mintForm.token1.address
            )
            if (mintForm.isLockFirstTokenVolume) {
                mintForm.amount1Desired = amountOtherCalced
                mintForm.amount1DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token1) ?? 0
            } else {
                mintForm.amount0Desired = amountOtherCalced
                mintForm.amount0DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token0) ?? 0
            }
        }),

        setMintFormAmountDesired: (state: LiquidityState, { isDesired0, desiredAmountDecimal, currentTick }: SetFormAmountDecimalDesiredParams) => produce(state, ({ mintForm }) => {
            let amountFilled = undefined as unknown as BigNumber
            if (isDesired0) {
                mintForm.isLockFirstTokenVolume = true;
                amountFilled = new BigNumber(desiredAmountDecimal).times(10 ** mintForm.token0.decimal)
                mintForm.amount0Desired = amountFilled
                mintForm.amount0DecimalDesired = desiredAmountDecimal
            } else {
                mintForm.isLockFirstTokenVolume = false;
                amountFilled = new BigNumber(desiredAmountDecimal).times(10 ** mintForm.token0.decimal)
                mintForm.amount1Desired = amountFilled
                mintForm.amount1DecimalDesired = desiredAmountDecimal
            }

            let leftPoint = mintForm.tickLower
            let rightPoint = mintForm.tickUpper
            if (mintForm.token0.address.toLowerCase() > mintForm.token1.address.toLowerCase()) {
                leftPoint = -mintForm.tickUpper
                rightPoint = -mintForm.tickLower
            }
            
            const amountOtherCalced = calciZiLiquidityAmountDesired(
                leftPoint,
                rightPoint,
                currentTick,
                // new BigNumber(0),
                amountFilled,
                mintForm.isLockFirstTokenVolume, 
                mintForm.token0.address, 
                mintForm.token1.address
            )
            if (mintForm.isLockFirstTokenVolume) {
                mintForm.amount1Desired = amountOtherCalced
                mintForm.amount1DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token1) ?? 0
            } else {
                mintForm.amount0Desired = amountOtherCalced
                mintForm.amount0DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token0) ?? 0
            }
        }),

        setMintFormDefault: (state: LiquidityState, currentTick: number) => produce(state, ({ mintForm }) => {
            mintForm.tickLower = price2NearestTick(mintForm.defaultTickLowerPrice, mintForm.fee, izumiFeeToTickSpacingMapping);
            mintForm.tickUpper = price2NearestTick(mintForm.defaultTickUpperPrice, mintForm.fee, izumiFeeToTickSpacingMapping);
            mintForm.tickLowerPrice = mintForm.defaultTickLowerPrice;
            mintForm.tickUpperPrice = mintForm.defaultTickUpperPrice;
            mintForm.tickLowerPriceDecimal = mintForm.defaultTickLowerPriceDecimal;
            mintForm.tickUpperPriceDecimal = mintForm.defaultTickUpperPriceDecimal;

            let leftPoint = mintForm.tickLower
            let rightPoint = mintForm.tickUpper
            if (mintForm.token0.address.toLowerCase() > mintForm.token1.address.toLowerCase()) {
                leftPoint = -mintForm.tickUpper
                rightPoint = -mintForm.tickLower
            }
            const amountFilled = new BigNumber(String(mintForm.isLockFirstTokenVolume ? mintForm.amount0Desired : mintForm.amount1Desired))
            const amountOtherCalced = calciZiLiquidityAmountDesired(
                leftPoint,
                rightPoint,
                currentTick,
                // new BigNumber(0),
                amountFilled,
                mintForm.isLockFirstTokenVolume, 
                mintForm.token0.address, 
                mintForm.token1.address
            )
            if (mintForm.isLockFirstTokenVolume) {
                mintForm.amount1Desired = amountOtherCalced
                mintForm.amount1DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token1) ?? 0
            } else {
                mintForm.amount0Desired = amountOtherCalced
                mintForm.amount0DecimalDesired = amount2Decimal(amountOtherCalced, mintForm.token0) ?? 0
            }
        }),

        setMintFormLockFirstTokenVolume: (state: LiquidityState, isLockFirstTokenVolume: boolean) => produce(state, ({ mintForm }) => {
            mintForm.isLockFirstTokenVolume = isLockFirstTokenVolume;
        }),

        toggleTokenOrder: (state: LiquidityState) => produce(state, ({ mintForm }) => {
            [mintForm.token0, mintForm.token1] = [mintForm.token1, mintForm.token0];
            [mintForm.tickLower, mintForm.tickUpper] = [-mintForm.tickUpper, -mintForm.tickLower];

            [mintForm.amount0Desired, mintForm.amount1Desired] = [mintForm.amount1Desired, mintForm.amount0Desired];
            [mintForm.amount0DecimalDesired, mintForm.amount1DecimalDesired] = [mintForm.amount1DecimalDesired, mintForm.amount0DecimalDesired];

            [mintForm.tickLowerPrice, mintForm.tickUpperPrice] = [1 / mintForm.tickUpperPrice, 1 / mintForm.tickLowerPrice];
            [mintForm.tickLowerPriceDecimal, mintForm.tickUpperPriceDecimal] = [1 / mintForm.tickUpperPriceDecimal, 1 / mintForm.tickLowerPriceDecimal];

            [mintForm.defaultTickLowerPrice, mintForm.defaultTickUpperPrice] = [1 / mintForm.defaultTickUpperPrice, 1 / mintForm.defaultTickLowerPrice];
            [mintForm.defaultTickLowerPriceDecimal, mintForm.defaultTickUpperPriceDecimal] = [1 / mintForm.defaultTickUpperPriceDecimal, 1 / mintForm.defaultTickLowerPriceDecimal];

            mintForm.isLockFirstTokenVolume = !mintForm.isLockFirstTokenVolume;
        }),
    },
    effects: (dispatch) => ({
        _getMintLiquidityFromUniswapCall(params: MintLiquidityFromiZiSwapParams): any {
            if (!params || !params.mintForm || !params.account || !params.liquidityManagerContract || !params.chainId) {
                return new Promise<TransactionReceipt>((_, reject) => reject('Check mintLiquidityFromUniswapParams fail'));
            }
            const { mintForm, account, liquidityManagerContract, gas, gasPrice, chainId } = params;
            const sortedToken = getSortedTokenAddr(mintForm.token0.address, mintForm.token1.address);
            const isFlipped = sortedToken[0] !== mintForm.token0.address;

            const amount0 = isFlipped ? mintForm.amount1Desired : mintForm.amount0Desired;
            const amount1 = isFlipped ? mintForm.amount0Desired : mintForm.amount1Desired;

            const mintRequest = {
                miner: params.account,
                tokenX: sortedToken[0],
                tokenY: sortedToken[1],
                fee: toContractFeeNumber(mintForm.fee),
                pl: isFlipped ? - mintForm.tickUpper : mintForm.tickLower,
                pr: isFlipped ? - mintForm.tickLower : mintForm.tickUpper,
                xLim: amount0.toFixed(0),
                yLim: amount1.toFixed(0),
                amountXMin: (amount0.multipliedBy(IZISWAP_MINT_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                amountYMin: (amount1.multipliedBy(IZISWAP_MINT_CONFIG.DESIRED_AMOUNT_TO_MIN_AMOUNT_FACTOR)).toFixed(0),
                deadline: (Date.now() / 1000 + IZISWAP_MINT_CONFIG.DEADLINE_OFFSET_MINUTES * 60).toFixed(0),
            } as MintRequest

            let value = '0';
            if (isGasToken(mintForm.token0, chainId)) {
                value = mintForm.amount0Desired.toFixed(0)
            }
            if (isGasToken(mintForm.token1, chainId)) {
                value = mintForm.amount1Desired.toFixed(0)
            }

            const mintMultiCall = [];
            mintMultiCall.push(liquidityManagerContract.methods.mint(mintRequest).encodeABI());
            mintMultiCall.push(liquidityManagerContract.methods.refundETH().encodeABI());

            const options = buildSendingParams(chainId, {
                from: account,
                value,
                gas,
                maxFeePerGas: gasPrice,
            }, gasPrice);
            return [liquidityManagerContract.methods.multicall(mintMultiCall), options];
        },
        mintLiquidityFromUniswap(params: MintLiquidityFromiZiSwapParams): any {
            const [calling, options] = dispatch.farmFixRangeiZiLiquidity._getMintLiquidityFromUniswapCall(params);
            return calling.send(options);
        },
        estimateMintGasLimit(params: MintLiquidityFromiZiSwapParams): any {
            const [calling, options] = dispatch.farmFixRangeiZiLiquidity._getMintLiquidityFromUniswapCall(params);
            return calling.estimateGas(options);
        },
        async refreshWalletMintLiquidity(params: { liquidityPoolKey: string, chainId: any }, rootState): Promise<void> {
            const { liquidityPoolKey } = params;
            const poolEntry = findPoolEntryByPoolKey(rootState.farmFixRangeiZi.poolEntryList, liquidityPoolKey);
            if (!poolEntry) { return; }

            const fee = poolEntry.meta.feeTier;
            const defaultTickLowerPriceDecimal = poolEntry.data.rewardMinPriceDecimalAByB;
            const defaultTickLowerPriceUndecimal = Number(priceDecimal2PriceUndecimal(poolEntry.meta.tokenA, poolEntry.meta.tokenB, defaultTickLowerPriceDecimal))

            const defaultTickUpperPriceDecimal = poolEntry.data.rewardMaxPriceDecimalAByB;
            const defaultTickUpperPriceUndecimal = Number(priceDecimal2PriceUndecimal(poolEntry.meta.tokenA, poolEntry.meta.tokenB, defaultTickUpperPriceDecimal))


            const mintForm = {
                token0: poolEntry.meta.tokenA,
                token1: poolEntry.meta.tokenB,
                fee: fee,

                // true means floor
                tickLower: poolEntry.data.rewardLowerTick,
                tickUpper: poolEntry.data.rewardUpperTick,

                tickLowerPrice: defaultTickLowerPriceUndecimal,
                tickUpperPrice: defaultTickUpperPriceUndecimal,
                tickLowerPriceDecimal: defaultTickLowerPriceDecimal,
                tickUpperPriceDecimal: defaultTickUpperPriceDecimal,

                defaultTickLowerPrice: defaultTickLowerPriceUndecimal,
                defaultTickUpperPrice: defaultTickUpperPriceUndecimal,
                defaultTickLowerPriceDecimal: defaultTickLowerPriceDecimal,
                defaultTickUpperPriceDecimal: defaultTickUpperPriceDecimal,

                liquidityPoolKey: liquidityPoolKey,

            } as MintForm;
            dispatch.farmFixRangeiZiLiquidity.setWalletMintForm(mintForm);
        }
    })
});
