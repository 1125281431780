import { useEffect } from 'react';
import { Box, Flex, Stack, useColorMode } from '@chakra-ui/react';

import Header from './components/Header/Header';
import useIsMobile from '../../hooks/useIsMobile';
import useLayoutType from '../../hooks/useLayoutType';
import { getColorThemeSelector } from '../../utils/funcs';
import HomePiece1 from './components/HomePiece1/HomePiece1';
import HomePiece2 from './components/HomePiece2/HomePiece2';
import HomePiece3 from './components/HomePiece3/HomePiece3';
import HomePiece4 from './components/HomePiece4/HomePiece4';
import HomePiece5 from './components/HomePiece5/HomePiece5';
import HomePiece6 from './components/HomePiece6/HomePiece6';
import HomePiece7 from './components/HomePiece7/HomePiece7';
import { announceConfig } from '../../config/announceBar/config';
import AnnouncementBar from '../../components/AnnounceBar/AnnounceBar';
import { LayoutStateEnum } from '../../state/models/pageLayout/pageLayout';

const Home: React.FC = () => {
    useEffect(() => {
        document.title = 'Home | Entropy';
    }, []);
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bgColor = colorTheme('#FBFBFB', '#100C1E');
    const layoutType = useLayoutType();
    const isMobile = useIsMobile();

    const bars = announceConfig.map((item: any, index: any) => {
        return <AnnouncementBar key={index} {...item}></AnnouncementBar>;
    });

    return (
        <Box
            id="home"
            w="100%"
            bgColor={bgColor}
            overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    display: 'none !important',
                },
            }}
            boxSizing="border-box"
            position="relative"
        >
            {bars}
            {(layoutType === LayoutStateEnum.sidebar || isMobile) && (
                <Header position="relative" mb="-55px" left="50%" transform="translateX(-50%)" />
            )}
            <Stack bg={colorTheme('linear-gradient(180deg, #E4E9EF 0%, rgba(245, 250, 255, 0) 100%);', '')}>
                <HomePiece1 />
                <HomePiece2 />
            </Stack>

            {/* <HomePiece3 /> */}
            <Flex
                direction="column"
                bgImage={colorTheme(
                    process.env.PUBLIC_URL + '/assets/home/cubeBg.png',
                    process.env.PUBLIC_URL + '/assets/home/cubeDarkBg.png'
                )}
                bgSize="cover"
            >
                <HomePiece4 />
                {/* <HomePiece5 /> */}
                <Box h="200px"></Box>
            </Flex>
            {/* <HomePiece6 /> */}
            <HomePiece7 />
        </Box>
    );
};

export default Home;
