import { Center, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Stack, Text, useColorMode } from '@chakra-ui/react';
import React, { useState } from 'react';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { links } from '../../../../config/links';
import placeholder from '../../../../assets/placeholder.png';
import { i_h1, i_text_copy_bold, i_text_d } from '../../../../style';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { ChainId } from '../../../../types/mod';
import { useTranslation } from 'react-i18next';
import { useHover } from '../../../../iZUMi-UI-toolkit/src/hooks/useHover';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import useLayoutType from '../../../../hooks/useLayoutType';
import { LayoutStateEnum } from '../../../../state/models/pageLayout/pageLayout';
import './HomePiece1.css';
import CustomButton from '../../../../components/CustomButton/CustomButton';
const HomePiece1: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();
    const layoutType = useLayoutType();

    const isMobile = useIsMobile();
    const [ref, isHovering] = useHover<HTMLDivElement>();
    const [isOpenList, setOpenList] = useState(false);

    return (
        <Flex
            backgroundSize="cover"
            w="100%"
            h={{ base: 'unset', md: '548px', xl: layoutType === LayoutStateEnum.sidebar ? '684px' : '584px' }}
            p={{
                base: '125px 20px 0px 20px',
                xl: layoutType === LayoutStateEnum.sidebar ? '135px 114px 394px 114px' : '35px 114px 394px 114px',
            }}
            boxSizing="border-box"
            overflow="hidden"
        >
            <Flex
                w={{ base: '363px', md: '710px', xl: '967px' }}
                direction={{ base: 'column', md: 'row' }}
                margin="auto"
                alignItems="center"
                textAlign={{ base: 'left', xl: 'unset' }}
            >
                <Flex w={{ base: '100%', xl: '467px' }} mr="0px" flex={50} direction="column">
                    <Flex alignItems="center">
                        <Image
                            className="homeImageRender"
                            w={{ base: '180px', sm: '404px' }}
                            // h={{ base: '57px', md: '62px', lg: '88px' }}
                            src={colorTheme(
                                process.env.PUBLIC_URL + '/assets/home/logo.png',

                                process.env.PUBLIC_URL + '/assets/home/darkLogo.svg'
                            )}
                            fallbackSrc={process.env.PUBLIC_URL + '/assets/home/logo.svg'}
                        ></Image>
                        {/* <Stack w="100%" spacing="17px">
                            <Text className={i_h1} fontSize="75px" fontFamily="Montserrat-Medium" lineHeight="53px" textAlign="start">
                                ENTROPY
                            </Text>
                            <Text className={i_h1} fontSize="55px" fontFamily="Montserrat" letterSpacing="0.15em">
                                EXCHANGE
                            </Text>
                        </Stack> */}
                    </Flex>
                    {/* <Text className={i_text_d} mt="32px" color={colorTheme('tertiary.700', 'tertiary.300')}>
                        <Text as="span" fontWeight="700" color="#50426F">
                            {t('Liquidity Redefined')}
                        </Text>{' '}
                        {t('- A multi-chain DeFi protocol providing One-Stop Liquidity as a Service (LaaS).')}
                    </Text> */}

                    {/* <Flex w="100%" direction="row" mt={{ base: '30px', md: '45px' }} justifyContent={{ base: 'center', md: 'unset' }}>
                        <>
                            <Flex
                                onClick={() => {
                                    setOpenList(!isOpenList);
                                }}
                                onMouseOver={() => {
                                    !isMobile && setOpenList(true);
                                }}
                                onMouseLeave={() => {
                                    setOpenList(false);
                                }}
                                ref={ref}
                            >
                                <Flex mt="-10px" pt="12px">
                                    <CustomButton
                                        w={{ base: '110px', md: '125px', xl: '125px' }}
                                        h="40px"
                                        variant="tertiary"
                                        text={t('Bridge') + ' ENT'}
                                        // ml={{ base: '10px', md: '13px', xl: '16px' }}
                                        className={i_text_copy_bold}
                                        onClick={() => {}}
                                    ></CustomButton>
                                </Flex>
                                <Flex zIndex="3">
                                    <Menu isOpen={isOpenList}>
                                        <MenuButton></MenuButton>
                                        <MenuList
                                            minW="0px"
                                            w="130px"
                                            className={i_text_d}
                                            bg={colorTheme('#FBFBFB', '#100C1E')}
                                            position="absolute"
                                            bottom="40px"
                                            left="-40px"
                                            fontSize="13px"
                                        >
                                            <MenuItem
                                                justifyContent="center"
                                                onClick={() => {
                                                    window.open(links.cbridgeLink);
                                                }}
                                            >
                                                cBridge
                                            </MenuItem>
                                        </MenuList>
                                    </Menu>
                                </Flex>
                            </Flex>

                            <CustomButton
                                w={{ base: '110px', md: '125px', xl: '125px' }}
                                h="40px"
                                // ml="10px"
                                ml={{ base: '10px', md: '13px', xl: '16px' }}
                                variant="primary3"
                                text={t('Learn more')}
                                className={i_text_copy_bold}
                                onClick={async () => {
                                    window.open(links.guide);
                                }}
                            ></CustomButton>
                        </>
                    </Flex> */}
                </Flex>
                <Center w={{ base: '308px', xl: '500px' }} h={{ base: '308px', xl: '500px' }}>
                    <Image
                        w={{ base: '308px', xl: '355px' }}
                        h={{ base: '308px', xl: '354px' }}
                        src={process.env.PUBLIC_URL + colorTheme('/assets/home/homePiece1/right.png', '/assets/home/homePiece1/right.png')}
                        fallbackSrc={'/assets/home/homePiece1/right.png'}
                    />
                </Center>
            </Flex>
        </Flex>
    );
};

export default HomePiece1;
