import { isAddress } from 'ethers/lib/utils';
import { isNumeric } from '../../../../utils/valid';
import { SwapForm } from './tradeSwap';
import { BigNumber } from 'bignumber.js'

export const validateSwapForm = (form: SwapForm, tokenBalance: number): string[] | undefined => {
    if (!form.tokenX || !isAddress(form.tokenX.address)) {
        return undefined; // initial state
        //return ['tokenX', 'invalidate token X address !'];
    }
    if (!form.tokenY || !isAddress(form.tokenY.address)) {
        return ['tokenX', 'invalidate token Y address !'];
    }
    if (!isNumeric(form.amountDecimal) || form.amountDecimal < 0) {
        return ['amount', 'please enter correct amount !'];
    }

    if (form.amountDecimal > tokenBalance) {
        return ['amount', 'Insufficient balance !'];
    }
};

export const getMaxAmountIn = (swapForm: SwapForm): string => {
    if (swapForm.desireMode) {
        const amountIn = swapForm.amount
        const slippagePercent = swapForm.slippagePercent
        const maxPayed = (new BigNumber(amountIn)).times(100 + slippagePercent).div(100).toFixed(0);
        return maxPayed
    } else {
        return swapForm.amount
    }
}
