import React from 'react';
import { Text, Stack, useColorMode, Image, HStack, BoxProps, IconButton, StackDirection } from '@chakra-ui/react';
import { Heading } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { i_text_copy_bold } from '../../../../../style';
import Info from '../../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { RepeatIcon } from '@chakra-ui/icons';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

type OrderIdProps = {
    value: string | number | null | undefined;
} & BoxProps;

export const OrderId: React.FC<OrderIdProps> = ({ value, ...rest }) => {
    return <Info w="70px" label="OrderId" alignItems="center" value={value as string} {...rest} />;
};

type AmountProps = {
    value: string | number | null;
    direction?: StackDirection;
} & BoxProps;

export const Amount: React.FC<AmountProps> = ({ value, direction, ...rest }) => {
    return (
        <Info
            w={{ base: '100%', sm: '81px' }}
            label="Amount"
            value={formatNumber(Number(value), 2, 2, true) as string}
            direction={direction}
            labelStyle={{ width: { base: '70px', sm: 'unset' } }}
            {...rest}
        />
    );
};

type PriceProps = {
    value: string | number | null;
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    handleToggle: () => void;
    toggle: boolean;
    valueStyle?: BoxProps;
    direction?: StackDirection;
} & BoxProps;

export const Price: React.FC<PriceProps> = ({ value, tokenA, tokenB, handleToggle, toggle, direction, valueStyle }) => {
    const v = toggle ? 1 / Number(value) : Number(value);
    return (
        <Info
            {...valueStyle}
            w={{ base: '100%', sm: '90px' }}
            label="Price"
            value={formatNumber(v, 2, 4, true) as string}
            tooltip={
                '1 ' +
                (toggle ? tokenB.symbol : tokenA.symbol) +
                ' = ' +
                formatNumber(v, 2, 4, true) +
                ' ' +
                (toggle ? tokenA.symbol : tokenB.symbol)
            }
            labelIcon={<IconButton size="sm" isRound={true} aria-label="" icon={<RepeatIcon />} variant="ghost" onClick={handleToggle} />}
            valueStyle={valueStyle}
            direction={direction}
            labelStyle={{ width: { base: '70px', sm: 'unset' } }}
        />
    );
};

type FilledProps = {
    value: string | number | null;
    all: string | number | null;
    direction?: StackDirection;
};

export const Filled: React.FC<FilledProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <Info
            w={{ base: '100%', sm: '80px' }}
            label="Filled"
            tooltip={formatNumber(Number(props.value)) + ' / ' + formatNumber(Number(props.all))}
            value={
                <HStack spacing="4px" flexShrink={0}>
                    <Image
                        w="12px"
                        h="12px"
                        src={
                            process.env.PUBLIC_URL + props.value === '0'
                                ? colorTheme('/assets/trade/lightNoFill.svg', '/assets/trade/darkNoFill.svg')
                                : colorTheme('/assets/trade/lightPartialFill.svg', '/assets/trade/darkPartialFill.svg')
                        }
                    />
                    <Heading level="5" whiteSpace="nowrap" fontSize={{ base: '14px !important', sm: '12px !important' }}>
                        {/* {formatNumber(Number(props.value)) +
                            ' / ' +
                            formatNumber(Number(props.all))} */}
                        {formatNumber((Number(props.value) / Number(props.all)) * 100, 2, 2) + '%'}
                    </Heading>
                </HStack>
            }
            fontSize="12px !important"
            direction={props.direction}
            labelStyle={{ width: { base: '68px', sm: 'unset' } }}
        />
    );
};

type CreateTimeProps = {
    direction?: 'column' | 'row';
    value: string;
} & BoxProps;

export const CreateTime: React.FC<CreateTimeProps> = (props) => {
    const { ...rest } = props;
    return (
        <Stack
            flexShrink={0}
            alignItems={{ base: 'start', sm: 'end' }}
            direction={props.direction ? props.direction : 'column'}
            w={{ base: '155px', sm: '80px' }}
            className={i_text_copy_bold}
            spacing={{ base: '2px', sm: '8px' }}
            {...rest}
        >
            <Text>{props.value.substring(0, 10)}</Text>
            <Text>{props.value.substring(11)}</Text>
        </Stack>
    );
};
