import { BoxProps, useColorMode, HStack, VStack, Text, Box, Image, IconButton, Icon, useInterval, Stack } from '@chakra-ui/react';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { i_text_copy, i_h4 } from '../../../../style';
import { formatNumber } from '../../../../utils/tokenMath';
import { TokenSymbol } from '../../../../types/mod';
import tokens from '../../../../config/tokens';
import { BsClock } from 'react-icons/bs';
import { VeiZiChart } from './VeiZiChart';
import { RootDispatch, RootState } from '../../../../state/store';
import { useSelector } from 'react-redux';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { getChain } from '../../../../config/chains';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import { useCallback, useEffect } from 'react';
import { InitGlobalDataParams } from '../../../../state/models/veiZi/types';
import { useTranslation } from 'react-i18next';

type StatsCardProps = {
    h?: any;
} & BoxProps;

export const StatsCard: React.FC<StatsCardProps> = (props) => {
    const { ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { veiZi } = useSelector((state: RootState) => state);
    const { web3, chainId } = useWeb3WithDefault();
    const chainName = getChain(chainId)?.name ?? 'Ethereum';

    const curveTimestamps = veiZi.globalData?.curveTimestamps ?? ([] as number[]);
    const totalVeiZiCurve = veiZi.globalData?.totalVeiZiCurve ?? ([] as number[]);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    useEffect(() => {
        console.info('trigger init global data', chainId);
        dispatch.veiZi
            .initGlobalData({
                chainId,
                web3,
            } as InitGlobalDataParams)
            .catch((e: any) => console.log('init global data error: ', e));
    }, [chainId, dispatch.veiZi, web3]);
    const refreshGlobalData = useCallback(
        () =>
            dispatch.veiZi
                .initGlobalData({
                    chainId,
                    web3,
                } as InitGlobalDataParams)
                .catch((e: any) => console.log('init global data error: ', e)),
        [chainId, dispatch.veiZi, web3]
    );
    useInterval(() => {
        console.log('trigger refresh global data ', chainId);
        refreshGlobalData();
    }, 10000);

    const tokenStat = (img: any, text: any, value: number, symbol: any) => {
        return (
            <Card variant="base" w="245px" h="70px" py="10px" pl="20px" {...rest}>
                <HStack>
                    <Image w="18px" h="18px" src={process.env.PUBLIC_URL + img} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {text}
                    </Text>
                </HStack>

                <HStack mt="12px">
                    <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                        {formatNumber(value, 2, 2)}
                    </Text>

                    <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                        {symbol}
                    </Text>
                </HStack>
            </Card>
        );
    };

    return (
        <>
            <Card
                variant="base"
                w={{ base: '100%', sm: '1089', xlp1: '1139px' }}
                h={{ base: 'unset', sm: '350px' }}
                mt="-15px !important"
                p={{ base: '20px', sm: '30px 20px 20px 30px' }}
            >
                <Stack w="100%" p="0" direction={{ base: 'column', sm: 'row' }} alignItems="center">
                    <VStack w="266px" h="336px" p="0" alignItems="left" spacing="20px">
                        <HStack alignItems="center" p="0" spacing="15px">
                            <Box w="11px" h="11px" borderRadius="6px" bgColor="#60DA00"></Box>
                            <VStack alignItems="start" spacing="0">
                                <Text className={i_h4} color={colorTheme('tertiary.700', 'tertiary.50')}>
                                    {t('veENT Status')}
                                </Text>
                                <Text className={i_text_copy} color={colorTheme('tertiary.600', 'tertiary.400')}>
                                    {chainName} {t('Network')}
                                </Text>
                            </VStack>
                        </HStack>
                        <VStack
                            w="266px"
                            h="180px"
                            bg={colorTheme(
                                'linear-gradient(180deg, #F1F1FF 0%, #FAFAFF 100%)',
                                'linear-gradient(180deg, #190F2E 0%, rgba(25, 13, 49, 0.63) 100%)'
                            )}
                            borderRadius="14px"
                            pt="13px"
                            px="10px"
                            spacing="16px"
                        >
                            {tokenStat(
                                tokens[TokenSymbol.IZI].icon,
                                t('Total ENT Locked') + ' :',
                                veiZi.globalData?.totaliZiLockedDecimal ?? 0,
                                // TokenSymbol.IZI
                                'ENT'
                            )}

                            {tokenStat(
                                '/assets/tokens/veiZi.svg',
                                t('Total veENT Amount') + ' :',
                                veiZi.globalData?.totalVeiZiDecimal ?? 0,
                                'veENT'
                            )}
                        </VStack>

                        <HStack>
                            <IconButton
                                size="sm"
                                aria-label=""
                                variant="ghost"
                                isRound={true}
                                icon={<Icon as={BsClock} boxSize="16px" />}
                            />
                            <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.100')} ml="0px !important">
                                {t('Average Lock Time')} :
                            </Text>

                            <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.50')}>
                                {formatNumber(veiZi.globalData?.avgLockYear ?? 0, 2, 2)}
                            </Text>

                            <Text fontSize="18px" className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                                {t('years')}
                            </Text>
                        </HStack>
                    </VStack>
                    <VeiZiChart
                        ml={{ base: '0px !important', sm: '40px !important' }}
                        height="270px"
                        curveTimeStamps={curveTimestamps}
                        curveValues={totalVeiZiCurve}
                        h="280px"
                    />
                </Stack>
            </Card>
        </>
    );
};
